import * as PIXI from "pixi.js";
import { getState, addListener, removeListener } from "@/store/base-state";
import { tariff } from "./tariff";
import { getMessage } from "./message";

interface IConfig {
    delay: number;
    player: number;
    message: string;
}

const INFO_STYLE = {
    fontFamily: "Roboto Slab",
    fontSize: 30,
    wordWrap: true,
    wordWrapWidth: 600,
    fill: "#ffffff",
    align: "center",
};

let container: PIXI.Sprite | null;
let timeout: number;

function init() {
    container = new PIXI.Sprite(PIXI.Texture.from("message"));
    container.visible = false;
    container.anchor.set(0.5);

    window.pixiApp?.stage.addChild(container);
}

export function showWarning(mode: string, config: IConfig) {
    config = Object.assign({ player: 2, delay: 0, message: null }, config);
    if (!container) {
        init();
    }

    if (!container) return;

    if (!container.visible) {
        switch (mode) {
            case "message":
                container.addChild(getMessage(config.message));
                container.on("pointertap", () => {
                    window.clearTimeout(timeout);
                    hideWarning();
                });
                break;
            case "budget":
                container.addChild(getMessage(config.message));
                addListener("timeBudget", budgetRefresh);
                break;

            case "tariff":
                container.addChild(tariff.getTariff());
                config.delay = 5000;
                container.on("pointertap", () => {
                    window.clearTimeout(timeout);
                    hideWarning();
                });
                break;
        }
    }
    container.scale.set(0.8);
    // if (config.player & 0x01) {
    //     container.scale.set(0.8);
    // } else {
    //     container.scale.set(1);
    // }
    container.angle = getState("angle")[config.player];
    container.visible = true;

    if (config.delay > 0) {
        window.clearTimeout(timeout);

        timeout = window.setTimeout(() => {
            hideWarning();
        }, config.delay);
    }
}

export function hideWarning() {
    if (!container) return;
    container.removeChildren();
    const parent = container.parent;
    parent.removeChild(container);
    container = null;
}

function budgetRefresh(budget) {
    if (container && budget > 60) {
        removeListener("timeBudget", budgetRefresh);
        hideWarning();
    }
}

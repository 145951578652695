import { getState, T_Lang } from "./base-state";
import { vsprintf } from "sprintf-js";

export interface I_LangData {
    textId: string;
    lang: T_Lang;
}

export type T_Text = {
    [key: string]: T_Lang;
};

export const texts: T_Text = {
    tariff: { hu: "Játék ár", en: "Playing cost", de: "Playing cost" },
    leftGameTime: { hu: "Játék idő: ", en: "Playing time: ", de: "Playing time: " },
    minutes: { hu: "perc ", en: "min", de: "min" },
    playingTime: { hu: "Játék idő ", en: "Playing time", de: "Playing time" },
    noBudget: {
        hu: "Elfogyott a credit!\n Kérjük jelezze a személyzetnek.",
        en: "No more credit!\nPlease call the attendant.",
        de: "No more credit!\n Please call the attendant.",
    },
    invalidCoupon: {
        hu: "Érvénytelen kupon.",
        en: "Invalid coupone",
        de: "Invalid coupone",
    },
};

export const getLangIds = (): string[] => {
    return Object.keys(texts);
};

export const setLang = (langs: I_LangData[]) => {
    try {
        for (let item of langs) {
            texts[item.textId] = item.lang;
        }
    } catch (e) {}
};

export function updateLang(textId: string, langs: T_Lang) {
    for (let key of Object.keys(texts)) {
        texts[textId][key] = langs[key];
    }
}

export function getMenu(menuId: string, ...args): string {
    return getLang(texts, menuId, args);
}

export function getText(textId, ...args): string {
    return getLang(texts, textId, ...args);
}

export function getLang(group: T_Text, textId: string, ...args): string {
    let t: string = "";
    const lang = getState("lang");

    if (Object.hasOwn(group, textId)) {
        if (Object.hasOwn(group[textId], lang)) {
            t = group[textId][getState("lang")];
        } else {
            // getLangFromServer(textId, lang);
            if (Object.hasOwn(group[textId], "en")) {
                t = group[textId]["en"];
            }
        }
    }
    if (args.length > 0) {
        t = vsprintf(t, args);
    }
    return t;
}

export function saveTexts() {
    const filename = "pollination.json";

    const data = JSON.stringify(texts, undefined, 4);

    var blob = new Blob([data], { type: "text/json" }),
        e = document.createEvent("MouseEvents"),
        a = document.createElement("a");

    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
    e.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    a.dispatchEvent(e);
}

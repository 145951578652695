import * as PIXI from "pixi.js";

export interface I_SpriteConfig {
    anchorX?: number;
    anchorY?: number;
    addToStage?: boolean;
}

export interface I_Sprite extends I_SpriteConfig {
    x: number;
    y: number;
    image?: string;
    atlas?: string;
    frame?: string;
    texture?: PIXI.Texture;
}

export default class MySprite extends PIXI.Sprite {
    activeFrame!: string;

    constructor(sprite: I_Sprite) {
        const base = Object.assign({ anchorY: 0.5, anchorX: 0.5, addToStage: false }, sprite);

        let texture!: PIXI.Texture;

        if (sprite.texture) {
            texture = sprite.texture;
        } else {
            if (sprite.atlas) {
                if (sprite.frame) {
                    texture = PIXI.Texture.from(sprite.frame) as PIXI.Texture;
                }
                // texture = Loader.shared.resources[sprite.atlas].textures[sprite.frame] as PIXI.Texture;
            } else if (sprite.image) {
                texture = PIXI.Texture.from(sprite.image);
                // texture = Loader.shared.resources[sprite.image].texture;
            }
        }
        super(texture);

        if (sprite.frame) {
            this.activeFrame = sprite.frame;
        }
        this.anchor.set(base.anchorX, base.anchorY);
        this.position.set(sprite.x, sprite.y);
        if (base.addToStage) {
            if (window.pixiApp) {
                window.pixiApp.stage.addChild(this);
            }
            // window.viewport.addChild(this);
        }
    }
    setAnchor(x: number, y: number): MySprite {
        this.anchor.set(x, y);
        return this;
    }

    setRandomAngle(): MySprite {
        this.angle = window.RNG.integerInRange(0, 360);
        return this;
    }

    setAngle(angle: number): MySprite {
        this.angle = angle;
        return this;
    }

    setTexture(key: string, frame: string = ""): MySprite {
        this.activeFrame = frame;
        this.texture = frame == null ? PIXI.Texture.from(key) : PIXI.Texture.from(frame);
        return this;
    }

    setName(name: string): MySprite {
        this.name = name;
        return this;
    }

    setPosition(x: number, y: number): MySprite {
        this.position.set(x, y);
        return this;
    }

    setScale(x: number, y?: number): MySprite {
        this.scale.x = x;
        this.scale.y = y == undefined ? x : y;
        return this;
    }

    setSize(w: number, h?: number): MySprite {
        this.width = w;
        this.height = h == undefined ? w : h;
        return this;
    }

    setVisible(visible: boolean): MySprite {
        this.visible = visible;
        return this;
    }
}

import WS from "../components/ws";
import * as BaseState from "../store/base-state";
import { emitter } from "./event";

interface I_QueueItem {
    cmd: string;
    func: Function;
    msgId?: number;
}

let messageId = 0;
let ws: WS | null = null;
let queue: Array<I_QueueItem> = [];
let reconnectTimer: number = 0;

export interface I_SendMessage {
    [key: string]: any;
}

export interface I_TableData {
    cmd: string;
    value?: number;
}

export interface I_RecMessage {
    id: string;
    data?: I_TableData;
}

export const connect = (callback?: (data: any) => void) => {
    if (ws == null || !ws.status) {
        if (callback) {
            addQueue({ cmd: "connect", func: callback, msgId: messageId });
        }
        ws = new WS("127.0.0.1:4920", messageHandler);
        ws.start();
    }
    // BaseState.setState("tableSocket", ws);
};

export const disconnect = (callback: () => void) => {
    addQueue({ cmd: "disconnect", func: callback, msgId: messageId });
};

export function isActive(): boolean {
    // const ws = BaseState.getState("tableSocket");
    return ws != null && ws.status;
}

export const send = (message: I_SendMessage, callback?: (data: any) => void) => {
    // const ws = BaseState.getState("tableSocket");
    if (ws == null) {
        connect();
        window.setTimeout(() => {
            send(message, callback);
        }, 200);
        return;
    }
    ws.send(message);
    if (callback) {
        addQueue({ cmd: message.cmd, func: callback, msgId: messageId });
    }
};

const messageHandler = (message: I_RecMessage) => {
    console.log(message);
    let queuItem: I_QueueItem | null;

    switch (message.id) {
        case "message":
            if (!message.data) {
                return;
            }
            queuItem = getQueue(message.data.cmd);
            if (queuItem) {
                queuItem.func(message.data);
            } else {
                switch (message.data.cmd) {
                    case "screenOn":
                        break;
                    case "gameTime":
                        BaseState.setState("gameTime", message.data.value);
                        emitter.emit("action", { action: "gameTime", data: { gameTime: message.data.value } });
                        break;
                    case "free":
                        BaseState.setState("free", message.data.value);
                        emitter.emit("action", { action: "free", data: { free: message.data.value } });
                        break;
                    case "gameCouponEnabled":
                        BaseState.setState("gameCouponEnabled", message.data.value);
                        emitter.emit("action", { action: "gameCouponEnabled", data: { gameCouponEnabled: message.data.value } });
                        break;
                }
            }
            break;
        case "connected":
            window.clearTimeout(reconnectTimer);
            reconnectTimer = 0;
            // reconnectActive = false;

            queuItem = getQueue("connect");
            if (queuItem && queuItem.func) {
                queuItem.func({ cmd: "connected" });
            } else {
            }
            break;
        case "disconnected":
            // if (reconnectTimer != 0) return;

            // reconnectTimer = window.setTimeout(() => {
            //     reconnectTimer = 0;
            //     connect();
            // }, 5000);
            queuItem = getQueue("disconnect");
            if (queuItem && queuItem.func) {
                queuItem.func({ cmd: "disconnect" });
            }

            break;
    }
};

const addQueue = (value: I_QueueItem) => {
    messageId++;
    value.msgId = messageId;
    queue.push(value);
};

const getQueue = (cmd: string): I_QueueItem | null => {
    for (let i = 0; i < queue.length; i++) {
        if (queue[i].cmd == cmd) {
            // connect stays, in case of reconnect restarts the init process
            if (cmd !== "connect") {
                const ret = queue.splice(i, 1);
                return ret[0];
            }
            return queue[i];
        }
    }
    return null;
};

import * as PIXI from "pixi.js";
import { gameSize } from "../store/base-state";

export const resize = () => {
    const size = gameSize();
    const ratioHW = size.h / size.w;
    const ratioWH = size.w / size.h;

    if (!window.pixiApp) {
        return;
    }

    const dw = document.documentElement.clientWidth;
    const dh = document.documentElement.clientHeight;
    let w,
        h = 0;

    // if (dw / dh >= ratio) {
    //     w = dh * ratio;
    //     h = dh;
    // } else {
    //     w = dw;
    //     h = dw / ratio;
    // }
    w = Math.round(dw);
    h = Math.round(dw * ratioHW);

    if (h > dh) {
        h = dh;
        w = dh * ratioWH;
    }

    window.pixiApp.stage.scale.set(w / size.w, h / size.h);

    // (renderer.view.style as PIXI.ICanvasStyle).width = w + "px";
    // (renderer.view.style as PIXI.ICanvasStyle).height = h + "px";
    // window.pixiApp.renderer.width = scale *

    window.pixiApp.stage.position.set(document.documentElement.clientWidth * 0.5, document.documentElement.clientHeight * 0.5);

    // renderer.resize(w, h);
};

import { getState, stageSize } from "@/store/base-state";
import * as PIXI from "pixi.js";
import Game from "./game-control";
import * as gamestate from "@/store/game-state";

export function initPixi() {
    // PIXI.extensions.remove(PIXI.InteractionManager);
    const view = document.getElementById("gameContainer") as HTMLCanvasElement;
    if (view !== undefined) {
        const app = new PIXI.Application<HTMLCanvasElement>({
            view,
            width: 1920,
            height: 1080,
            autoDensity: true,
            clearBeforeRender: false,
            resizeTo: window,
            antialias: false,
            resolution: window.devicePixelRatio || 1,
        });

        // document.body.appendChild(app.view);
        // const evs = new PIXI.EventSystem(window.pixiApp.renderer);
        // evs.domElement = view;
        // evs.setTargetElement(view);
        const size = stageSize();
        window.pixiApp = app;
        window.pixiApp.stage.position.set(size.hw, size.hh);
    }
}

export async function loadAssets() {
    const gameData: gamestate.I_GameData[] = gamestate.getState("gameData");
    try {
        PIXI.Assets.add("atlas", "images/atlas.json");
        await PIXI.Assets.load("atlas");

        for (let item of gameData) {
            PIXI.Assets.add(item.gameName, `images/icons/${item.gameName}.webp`);
            await PIXI.Assets.load(item.gameName);
        }

        const bg = getState("lobbyBackgroundUrl");
        // if (bg) {
        //     PIXI.Assets.add("bg", bg);
        //     await PIXI.Assets.load("bg");
        // }
    } catch (e) {
        console.error(e);
    }

    new Game();
}

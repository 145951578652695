import * as PIXI from "pixi.js";
import MySprite from "../components/sprite";
import { getState } from "../store/base-state";
import { I_MenuItem } from "../store/interfaces";

const MENU_STYLE: Partial<PIXI.ITextStyle> = {
    fontFamily: "Roboto Slab",
    fontSize: 24,
    fill: "#000000",
    align: "left",
};

export default class SubMenu extends PIXI.Container {
    constructor(public player: number, x: number, y: number, items: I_MenuItem[], public callback: (itemId: string) => void) {
        super();
        this.position.set(x, y);
        this.create(items);
    }

    create(items: I_MenuItem[]) {
        const bg = new MySprite({ x: 0, y: 0, atlas: "atlas", frame: "menu-panel1", anchorY: 0 });
        this.addChild(bg);
        let x = -80;
        let y = 30;

        for (let item of items) {
            if (!item.visible) {
                continue;
            }
            const lang: string = getState("lang")[this.player];
            const label = item.label ? item.label[lang] : "";

            const text = new PIXI.Text(label, new PIXI.TextStyle(MENU_STYLE));
            text.position.set(x, y);
            text.eventMode = "static";
            this.addChild(text);
            text.on("pointertap", () => {
                this.eventClickMenuItem(item.id);
            });

            y += 40;
        }
    }

    eventClickMenuItem = (id: string) => {
        this.callback(id);
    };
}

import { getLang, getText } from "@/store/lang";
import * as PIXI from "pixi.js";
import { getState, addListener, removeListener } from "@/store/base-state";
import { secToMin } from "@/tools/utils";

const INFO_STYLE: Partial<PIXI.ITextStyle> = {
    fontFamily: "Roboto Slab",
    fontSize: 26,
    wordWrap: true,
    wordWrapWidth: 600,
    fill: "#ffffff",
    align: "left",
};

let container: PIXI.Container;
let timeout: number;

function getTariff(): PIXI.Container {
    container = new PIXI.Container();

    const lang = getState("lang")[2];
    let y = -20;

    let style = new PIXI.TextStyle(INFO_STYLE);
    style.fill = "#ffff00";
    let pText = new PIXI.Text("", style);
    pText.name = "gameTime";
    pText.y = y;
    pText.anchor.set(0.5, 0);
    container.addChild(pText);
    setGameTime(getState("gameTime"));
    y += 60;
    let index = 0;
    for (let item of getState("tariff")) {
        let text = `${Math.floor(item.time / 60)} ${getText("minutes")} - ${item.credit}${getState("currency")}`;
        let pText = new PIXI.Text(text, INFO_STYLE);
        pText.name = `tariff${index}`;
        pText.y = y;
        pText.anchor.set(0.5, 0);
        container.addChild(pText);

        y += 40;
        index++;
    }
    y += 20;
    style = new PIXI.TextStyle(INFO_STYLE);
    style.fontSize = 22;
    pText = new PIXI.Text(getState("whereToBuy")[lang], style);
    pText.name = "whereToBy";
    pText.y = y;
    pText.anchor.set(0.5, 0);
    container.addChild(pText);
    addListener("gameTime", refreshGameTime);
    addListener("free", freeStateChanged);
    container.on("removed", () => {
        removeListener("gameTime", refreshGameTime);
        removeListener("free", freeStateChanged);
    });
    return container;
}

function hide() {
    if (container) {
        container.removeChildren();
        const parent = container.parent;
        parent.removeChild(container);
        container.destroy();
    }
    removeListener("gameTime", refreshGameTime);
}

function refreshGameTime(gameTime) {
    if (container) {
        setGameTime(gameTime);
    }
}

function setGameTime(gameTime) {
    // container.cacheAsBitmap = false;
    (container.getChildByName("gameTime") as PIXI.Text).text = `${getText("leftGameTime")}: ${secToMin(gameTime)}`;
    // container.cacheAsBitmap = true;
}

function changeLang(player: number) {
    if (!container) return;
    (container.getChildByName("whereToBy") as PIXI.Text).text = getState("whereToBuy")[getState("lang")[player]];
    setGameTime(getState("gameTime"));
    let index = 0;
    for (let item of getState("tariff")) {
        let name = `tariff${index}`;
        (container.getChildByName(name) as PIXI.Text).text = `${Math.floor(item.time / 60)} ${getText("minutes")} - ${item.credit}${getState("currency")}`;
    }
}

function freeStateChanged(free) {
    if (container != null && free) {
        hide();
    }
}

export const tariff = {
    getTariff: getTariff,
    hide: hide,
    changeLang: changeLang,
};

/**
 * Created by janos on 2017.09.01..
 */
import { Howl, Howler } from "howler";

const soundList = [
    ["slide", "sounds/slide"],
    ["blup", "sounds/blup"],
    ["gameStart", "sounds/game_start"],
];

class Sound {
    sounds: {};

    constructor() {
        // for (let i = 0; i < 11; i++) {
        //     soundList.push([`count${i}`, `sounds/count${i}.mp3`]);
        // }
        this.sounds = {};
        for (let s of soundList) {
            this.sounds[s[0]] = {
                howl: new Howl({
                    src: [`${s[1]}.mp3`],
                    preload: true,
                    loop: false,
                    html5: true,
                    onload: () => {
                        this.sounds[s[0]].loaded = true;
                    },
                }),
                loaded: false,
            };
            // this.sounds[s[0]].howl.once("load", () => {
            //     this.sounds[s[0]].loaded = true;
            // });
        }
    }

    play(sound, volume = 1) {
        if (this.sounds[sound]) {
            this.sounds[sound].howl.play();
            this.sounds[sound].howl.volume(volume);
        }
    }

    stopAll() {
        for (let key of Object.keys(this.sounds)) {
            this.stop(key);
        }
    }

    stop(sound) {
        if (this.sounds[sound]) {
            this.sounds[sound].howl.stop();
        }
    }

    pause(sound) {
        if (this.sounds[sound]) {
            this.sounds[sound].howl.pause();
        }
    }

    volume(sound, volume) {
        if (this.sounds[sound]) {
            this.sounds[sound].howl.volume(volume);
        }
    }

    fade(sound, from, to, duration, remove = false) {
        if (this.sounds[sound]) {
            if (this.sounds[sound].howl.playing()) {
                this.sounds[sound].howl.fade(from, to, duration);
            }
            if (remove) {
                this.sounds[sound].howl.onfade(() => {
                    this.remove(sound);
                });
            }
        }
    }

    isLoaded(sound) {
        return this.sounds[sound].loaded;
    }

    add(name, src, callback) {
        this.stop(name);

        if (this.sounds[name] && this.sounds[name].howl && this.sounds[name].howl._src == src) {
            if (callback) {
                callback(name);
            }
            return;
        }
        this.sounds[name] = {
            howl: new Howl({
                src: [src],
                preload: true,
                onload: () => {
                    if (callback) {
                        callback(name);
                    }
                },
            }),
            loaded: false,
        };
    }

    remove(name) {
        delete this.sounds[name];
    }
}

let sound: Sound | null = null;

export default function getSound(): Sound {
    if (sound == null) {
        sound = new Sound();
    }
    return sound;
}
// const sound = new Sound();
// export default sound;

import { gsap } from "gsap";
import getSound from "@/modules/sound";

let tweens: GSAPTween[] = [];
let killedAll = false;

export function to(target, vars: gsap.TweenVars): GSAPTween {
    // if (getState("gameState") == GAME_STATE.DISABLED) return null;

    clean();
    const tween = gsap.to(target, vars);
    tweens.push(tween);
    // console.log("TWEENS ADD: ", tweens.length);

    return tween as GSAPTween;
}

export function delayedCall(delay: number, fn: () => void, params: any[] = []): GSAPTween {
    const tween = gsap.delayedCall(delay, fn, params);
    tweens.push(tween);
    return tween as GSAPTween;
}

export function scaleAnim(target: any) {
    getSound().play("blup");
    to(target, { duration: 0.1, yoyo: true, repeat: 1, pixi: { scale: 0.9 } });
}

export function clear(tween: GSAPTween | gsap.core.Omit<gsap.core.Tween, "then">) {
    tweens = tweens.filter((t: GSAPTween) => t != tween);
}

function clean() {
    tweens = tweens.filter((tween: GSAPTween) => {
        return tween.isActive();
    });

    // console.log("TWEENS CLEAR: ", tweens.length);
}

export function killAll() {
    // console.log("TWEENS CLEAR ALL: ", tweens.length);
    tweens.forEach((tween: GSAPTween) => {
        tween.kill();
    });
    tweens.length = 0;
    killedAll = true;
}

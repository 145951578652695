import { gameSize, stageSize } from "../store/base-state";
import { getState } from "@/store/base-state";

export async function wait(delay: number) {
    return new Promise((resolve) => {
        window.setTimeout(function () {
            resolve(null);
        }, delay);
    });
}

export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "mobile";
    }
    return "desktop";
};

export const getTanDeg = (deg: number): number => {
    let rad = (deg * Math.PI) / 180;
    return Math.tan(rad);
};

export const getRad = (deg: number): number => {
    return (deg * Math.PI) / 180;
};

export const clamp = (num, min, max) => {
    return Math.min(Math.max(num, min), max);
};

/**
 * the stage pos is in center (0, 0)
 * all pos is relative to th edges, base is player2
 * @param player
 * @param x  left -, right +
 * @param y  top -, bottom +
 */
export function getObjectBoardPosByEdge(pid: number, x: number, y: number): { x: number; y: number; angle: number } {
    const pos = { x, y, angle: getState("angle")[pid] };
    const gs = gameSize();
    const hw = gs.w * 0.5;
    const hh = gs.h * 0.5;

    switch (pid) {
        case 0:
            x *= -1;
            y *= -1;
        case 2:
            break;
        case 1:
            const t = y;
            y = -x;
            x = t;
            break;

        case 3:
            const t2 = y;
            y = x;
            x = -t2;
            break;
    }

    if (x < 0) {
        pos.x = -hw - x;
    } else if (x > 0) {
        pos.x = hw - x;
    } else {
        pos.x = x;
    }
    if (y < 0) {
        pos.y = -hh - y;
    } else if (y > 0) {
        pos.y = hh - y;
    } else {
        pos.y = y;
    }

    return pos;
}

/**
 * the stage pos is in center (0, 0)
 * all pos is relative to th edges, base is player2
 * @param player
 * @param x  left -, right +
 * @param y  top -, bottom +
 */
export function getObjectBoardPosXCenterYEdge(pid: number, x: number, y: number): { x: number; y: number; angle: number } {
    const pos = { x, y, angle: getState("angle")[pid] };
    const gs = gameSize();
    const hw = gs.w * 0.5;
    const hh = gs.h * 0.5;

    switch (pid) {
        case 0:
            pos.x = -x;
            pos.y = -hh - y;
            break;
        case 2:
            pos.y = hh + y;
            break;
        case 1:
            pos.y = -x;
            pos.x = hw + y;
            break;

        case 3:
            pos.y = x;
            pos.x = -hw - y;
            break;
    }

    return pos;
}

import * as gamemodel from "./model/game";
import * as PIXI from "pixi.js";
import { GAME_STATE } from "./game-state";

export interface I_Tariff {
    credit: number;
    time: number;
}

export interface T_Lang {
    [key: string]: string;
}

export interface I_LobbyDataResponse {
    tid?: string;
    version?: number;
    currency?: string;
    lobbyBackgroundImage: string;
    token: string;
    tariff?: I_Tariff[];
    games: gamemodel.I_GameData[];
    languages: string[];
    timestamp?: string;
    whereToBuy?: T_Lang;
    adminPinCode?: string;
    tableColor?: string;
    gameCouponEnabled?: boolean;
    private: boolean;
    creditSystem: boolean;
}

export interface I_Registration {
    tid: string;
    tableName: string;
    validTo: Date;
    network: string;
}

interface I_BaseState {
    tz: number;
    gameName: string;
    basePath: string;
    touchDisabled: boolean;
    lang: string[];
    enabledLangs: string[];
    authenticated: boolean;
    whereToBuy: T_Lang;
    tid: string | null;
    hid: string | null;
    free: boolean;
    gameTime: number;
    angle: number[];
    textAngle: number[];
    adminCode: string;
    paymentVisible: boolean;
    tariff: I_Tariff[];
    currency: string | null;
    lastGameClick: number;
    tableName: string;
    openingHours: number[];
    startstop: boolean;
    startstopData: { room: number; startTime: number; cost: number; gameTime: number; stopTime: number };
    gameSize: { w: number; h: number };
    colorInt: number[];
    interfaceSize: { w: number; h: number };
    cardSize: { w: number; h: number; gap: number; cardNumber: number };
    lobbyBackgroundUrl: string | null;
    backgroundImage: PIXI.Sprite | null;
    registration: I_Registration | null;
    tableModel: string | null;
    OS: string | null;
    tableServerVersion: number;
    platform: string;
    volume: number;
    version: number;
    timeBudget: number;
    ip: string | null;
    creditSystem: boolean;
    adminPinCode: string;
    tableColor: string;
    gameCouponEnabled: boolean;
    private: boolean;
    gameState: GAME_STATE;
    environmentVersion: string;
    gameEndpoint: string;
}

type BaseStateKeys = keyof I_BaseState;

const state: I_BaseState = {
    version: 93,
    tz: -new Date().getTimezoneOffset(),
    gameName: "lobby",
    basePath: "/api",
    touchDisabled: true,
    lang: ["en", "en", "en", "en"],
    adminCode: "h",
    enabledLangs: ["hu", "en", "de", "ro"],
    authenticated: false,
    whereToBuy: {
        hu: "",
        en: "",
        de: "",
        ro: "",
    },
    tid: null,
    hid: null,
    free: false,
    gameTime: 0,
    angle: [180, 270, 0, 90],
    textAngle: [-90, 0, 90, 180],
    paymentVisible: false,
    tariff: [],
    currency: null,
    lastGameClick: Date.now(),
    tableName: "",
    openingHours: [7, 23],
    startstop: false,
    startstopData: {
        room: 0,
        startTime: 0,
        cost: 0,
        gameTime: 0,
        stopTime: 0,
    },
    gameSize: { w: 1920, h: 1080 },
    colorInt: [0x386dff, 0x00ff00, 0xff0000, 0xffff00],
    interfaceSize: { w: 800, h: 300 },
    lobbyBackgroundUrl: null,
    backgroundImage: null,
    cardSize: { w: 180, h: 270, gap: 0, cardNumber: 0 },
    registration: null,
    tableModel: null,
    OS: null,
    tableServerVersion: 0,
    platform: "android",
    volume: 5,
    ip: null,
    timeBudget: 0,
    creditSystem: false,
    adminPinCode: "",
    tableColor: "#ffffff",
    gameCouponEnabled: false,
    private: false,
    gameState: GAME_STATE.DISABLED,
    environmentVersion: "",
    gameEndpoint: "",
};

const listeners: Map<string, Set<Function>> = new Map();
// export const getState = (key: string) => {
//     return state[key];
// };

export function setState<K extends BaseStateKeys>(key: K, value: any) {
    const old = state[key];
    state[key] = value;
    if (listeners.get(key) && old != value) {
        emitEvent(key, value);
    }
    return state[key];
}

export function getState<K extends BaseStateKeys>(key: K): any {
    return state[key];
}

export function getLang(player: number): string {
    return state.lang[player];
}

export function canPlay(): boolean {
    return (state.timeBudget > 60 || !state.creditSystem) && (state.free || state.gameTime) ? true : false;
}

export function addListener(event: string, listener: Function) {
    const handlers = listeners.get(event);
    if (handlers) {
        handlers.add(listener);
    } else {
        const handlers = new Set([listener]);
        listeners.set(event, handlers);
    }
}

export function removeListener(event: string, listener: Function) {
    const handlers = listeners.get(event);
    if (handlers) {
        handlers.delete(listener);
        if (handlers.size == 0) {
            listeners.delete(event);
        }
    }
}

export function emitEvent(event: string, value: any = null) {
    const handlers = listeners.get(event);
    if (handlers) {
        handlers.forEach((func) => func(value));
    }
}

export function getTableColor(player: number): number {
    const color = state.tableColor.substr(1);
    return parseInt(color, 16);
}

export function stageSize(): { w: number; h: number; hw: number; hh: number } {
    const canvas = document.querySelector("canvas") as HTMLCanvasElement;
    const w = canvas.width;
    const h = canvas.height;

    return { w, h, hw: w * 0.5, hh: h * 0.5 };
}

export function gameSize(): { w: number; h: number } {
    return { w: 1920, h: 1080 };
}

function setGameState(gameState: GAME_STATE) {
    // console.log("NEW STATE: ", gameState);
    state.gameState = gameState;
}

export function getApi(): string {
    if (location.host.startsWith("localhost")) {
        return `/api`;
    }
    // const domain = window.location.hostname.match(/\w*\.\w*$/gi)?.[0];
    return "https://lobby.touchgametable.com/api";
}

import { Container, Graphics } from "pixi.js";
import * as gamestate from "../store/game-state";
import Card from "./card";
import * as tween from "@/modules/tween-handler";

const CARD_WIDTH = 180;
const CARD_GAP = 10;
const WIDTH = 756;
const HEIGHT = 274;

const OFFSET = { x: 0, y: -190 };
export default class Carousel extends Container {
    cards: Card[] = [];
    slidePos = 0;
    tween!: GSAPTween;

    constructor(public pid: number) {
        super();
        this.create();
    }

    clean() {
        this.cards = [];
    }

    async create() {
        const games = gamestate.getState("gameData");
        let i = 0;
        const x = -WIDTH * 0.5 + CARD_WIDTH * 0.5 + CARD_GAP * 0.5;
        while (this.cards.length < 4) {
            let card = new Card(games[i], this.pid);
            card.position.set(x + i * (CARD_WIDTH + CARD_GAP), 0);
            this.cards.push(card);
            card.visible = true;
            this.addChild(card);
            i++;
        }
    }

    async moreCard() {
        const games = gamestate.getState("gameData");
        for (let i = 4; i < games.length; ++i) {
            let card = new Card(games[i], this.pid);
            this.cards.push(card);
        }
    }

    setMask() {
        const graphics = new Graphics();
        graphics.beginFill(0x000000);

        graphics.drawRect(-WIDTH * 0.5, this.position.y, WIDTH, HEIGHT);
        graphics.endFill();
        graphics.cacheAsBitmap = true;
        this.parent.addChild(graphics);
        this.mask = graphics;
    }

    slideLeft() {
        if (this.tween && this.tween.isActive()) {
            return;
        }

        const x = WIDTH * 0.5 - CARD_WIDTH * 0.5;
        // getSound().play("slide");
        for (let i = 1; i < 5; i++) {
            this.slidePos--;
            if (this.slidePos < 0) {
                this.slidePos = this.cards.length - 1;
            }
            let pos = this.slidePos % this.cards.length;
            const card = this.cards[pos];

            card.x = -i * 190 - x;
            card.visible = true;
            this.addChildAt(card, 0);
        }
        const children: Card[] = this.children as Card[];

        children.forEach((card: Card) => {
            card.cacheAsBitmap = true;
        });
        this.tween = tween.to(this.children, {
            x: "+=762",
            duration: 0.3,
            // ease: "expo.out",
            onComplete: () => {
                this.removeChildren(4, 8);
                children.forEach((card: Card) => {
                    card.cacheAsBitmap = false;
                });
            },
        });
    }

    slideRight() {
        if (this.tween && this.tween.isActive()) {
            return;
        }
        const x = WIDTH * 0.5 + CARD_WIDTH * 0.5;
        // getSound().play("slide");
        for (let i = 0; i < 4; i++) {
            let pos = (this.slidePos + 4) % this.cards.length;

            const card = this.cards[pos];

            card.x = i * 190 + x;
            card.visible = true;
            this.addChild(card);
            this.slidePos++;
        }
        this.tween = tween.to(this.children, {
            x: "-=750",
            duration: 0.3,
            // ease: "expo.out",
            onComplete: () => {
                this.removeChildren(0, 4);
                (<Card[]>this.children).forEach((card: Card) => {
                    card.cacheAsBitmap = false;
                });
            },
        });
    }

    changeLang(lang: string) {
        this.cards.forEach((card: Card) => {
            card.setLang(lang);
        });
    }
}

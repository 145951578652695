import { getTransitionRawChildren } from "@vue/runtime-core";
import { getState } from "../store/base-state";
import { I_Lang, I_MenuItem } from "../store/interfaces";

export namespace menuitems {
    type T_Lang = {
        [key: string]: string;
    };

    export function get(): I_MenuItem[] {
        return [
            {
                id: "lang",
                visible: true,
                label: {},
                children: [
                    { id: "hu", label: { hu: "Magyar" } },
                    { id: "en", label: { en: "English" } },
                    { id: "de", label: { de: "Deutsch" } },
                    { id: "ro", label: { ro: "Roman" } },
                ],
            },

            // {
            //     id: "tariff",
            //     label: { hu: "Játék ár", en: "Playing cost", de: "Playing cost" },
            // },
            // { id: "empty" },
            // { id: "empty" },
            {
                id: "options",
                label: { hu: "Beállítások", en: "Options", de: "Options" },
                visible: true,
                children: [
                    { id: "tariff", label: { hu: "Játék ár", en: "Playing cost", de: "Playing cost" }, visible: true },
                    { id: "volume", label: { hu: "Hangerő", en: "Volume", de: "Volume" }, visible: true },
                    { id: "wifi", label: { hu: "Wi-Fi", en: "Wi-Fi", de: "Wi-Fi" }, visible: false },

                    { id: "lobbyReload", label: { hu: "Reload", en: "Reload", de: "Reload" }, visible: true },
                    {
                        id: "admin",
                        label: { hu: "Admin", en: "Admin", de: "Admin" },
                        visible: true,
                        children: [
                            { id: "adminWifi", label: { hu: "WiFi", en: "WiFi", de: "WiFi" }, visible: true },
                            { id: "update", label: { hu: "Frissítés", en: "Update", de: "Update" }, visible: true },
                            { id: "clearChromeCache", label: { hu: "Cache törlés", en: "Clear cache", de: "Clear cache" }, visible: true },
                            { id: "info", label: { hu: "Infó", en: "Info", de: "Info" }, visible: true },
                        ],
                    },
                    {
                        id: "system",
                        label: { hu: "System", en: "System", de: "System" },
                        visible: true,
                        children: [
                            { id: "info", label: { hu: "Infó", en: "Info", de: "Info" }, visible: true },
                            { id: "update", label: { hu: "Frissítés", en: "Update", de: "Update" }, visible: true },
                            { id: "exit", label: { hu: "Kilépés", en: "Exit", de: "Exit" }, visible: true },
                            { id: "adb", label: { hu: "ADB", en: "ADB", de: "ADB" }, visible: getState("platform") == "android" },
                            { id: "reset", label: { hu: "Reset", en: "Reset", de: "Reset" }, visible: true },
                        ],
                    },
                ],
            },
            {
                id: "player",
                label: { hu: "Játékos", en: "Player", de: "Player" },
                visible: getState("gameCouponEnabled"),
                children: [{ id: "gameCoupon", label: { hu: "Játék kupon", en: "Game coupon", de: "Game coupon" }, visible: getState("gameCouponEnabled") }],
            },
        ];
    }

    export function getLabel(item: I_MenuItem, lang: string): string {
        if (item.label) {
            if (item.label[lang]) {
                return item.label[lang];
            }
            if (item.label["en"]) {
                return item.label["en"];
            }
        }
        return "";
    }
}

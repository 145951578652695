import * as PIXI from "pixi.js";
import MySprite from "../sprite";

const STYLE: Partial<PIXI.ITextStyle> = {
    fontFamily: "Roboto Slab",
    fontSize: 24,
    fill: "#000000",
    align: "center",
};

export default class Display extends PIXI.Container {
    digits: string = "";
    cursorFlag: boolean = false;
    elapsedTime: number = 0;
    digitsText: PIXI.Text;

    constructor() {
        super();
        this.addChild(new MySprite({ x: 0, y: 0, atlas: "atlas", frame: "numpad-display" }));

        this.digitsText = new PIXI.Text("", new PIXI.TextStyle(STYLE));
        this.digitsText.anchor.set(0.5);
        this.digitsText.position.set(0, 0);
        this.addChild(this.digitsText);
        this.name = "display";
        // Ticker.shared.add(this.update);
    }

    addDigit(digit: string): boolean {
        if (this.digits.length == 10) {
            return false;
        }
        // this.digits = `${this.digits.slice(0, this.digits.length - 1)}${digit}|`;
        this.digits = `${this.digits}${digit}`;
        this.refresh();
        return true;
    }

    back() {
        // this.digits = `${this.digits.slice(0, this.digits.length - 2)}|`;
        if (this.digits.length > 0) {
            this.digits = `${this.digits.slice(0, this.digits.length - 1)}`;
            this.refresh();
        }
    }

    clear() {
        // this.digits = "|";
        this.digits = "";
        this.refresh();
    }

    getCode(): string {
        return this.digits;
    }

    refresh() {
        this.digitsText.text = this.digits;
    }

    update = (delta: number) => {
        this.elapsedTime += delta;
        if (this.elapsedTime > 30) {
            this.elapsedTime = 0;
            this.digits = `${this.digits.slice(0, this.digits.length - 1)}`;
            this.digits = this.cursorFlag ? `${this.digits}|` : `${this.digits} `;
            this.digitsText.text = this.digits;
            this.cursorFlag = !this.cursorFlag;
        }
    };
}

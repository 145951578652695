import * as BaseState from "../store/base-state";
import MySprite from "../components/sprite";
import Game from "../game/game-control";
import * as PIXI from "pixi.js";

import { getText } from "../store/lang";
import { getState, addListener, setState } from "../store/base-state";
import { I_Lang, I_MenuItem } from "../store/interfaces";

import LangSelector from "./lang_selector";
import OptionsSelector from "./options_selector";
import Volume from "./volume";
import Numpad from "../components/numpad/numpad";
import http from "../common/http_request";
import SubMenu from "./submenu";
import GameCost from "./tariff";
import { showWarning } from "../components/warning/warning";
import { menuitems } from "./menu_items";
import Info from "./info";
import * as table from "@/modules/table";
import { forceReload } from "../common/http_request";
import { scaleAnim } from "@/modules/tween-handler";

const WIDTH = 756;
const HEIGHT = 270;

// relative to center
const ITEM_POS = {
    x: 1920 * 0.5 - WIDTH * 0.5,
    y: 270,
    w: 180,
    gap: 10,
};

const MENU_STYLE: Partial<PIXI.ITextStyle> = {
    fontFamily: "Roboto Slab",
    fontSize: 24,
    fill: "#000000",
    align: "center",
};

const OFFSET = { x: 375, y: -12 };

export default class MenuControl extends PIXI.Container {
    menuBarContainer: PIXI.Container;
    hideTimer: number = 0;
    activeMenu: PIXI.Container | null = null;
    langSensitive: { text: PIXI.Text; label: I_Lang }[] = [];
    activeLang: string;

    constructor(public pid: number) {
        super();
        this.activeLang = BaseState.getState("lang")[pid];

        addListener("free", this.redrawMenu);
        addListener("gameCouponEnabled", this.redrawMenu);

        this.position.set(-OFFSET.x, -OFFSET.y);
        // this.angle = BaseState.getState("angle")[this.pid];

        this.createMenuButton();
        this.menuBarContainer = new PIXI.Container();
        this.createMenuBar();
    }

    createMenuButton() {
        const btn = new PIXI.Sprite(PIXI.Texture.from("menu"));
        btn.anchor.set(0.5);
        btn.position.set(-60, -20);
        btn.tint = BaseState.getTableColor(this.pid);
        btn.interactive = true;
        // btn.alpha = 0.8;
        btn.name = "main";
        btn.on("pointertap", () => {
            this.menuItemClick("main");
            scaleAnim(btn);
        });
        this.addChild(btn);
    }

    redrawMenu = (state) => {
        const childrens = this.menuBarContainer.removeChildren() as PIXI.Container[];
        childrens.forEach((child: PIXI.Container) => {
            child.destroy({ children: true, texture: false, baseTexture: false });
        });
        window.setTimeout(() => {
            this.createMenuBar();
        }, 0);
    };

    createMenuBar() {
        this.menuBarContainer.visible = false;

        this.menuBarContainer.position.set(91, -50);
        let x = 0;
        const y = 0;
        // console.log(getMenuItems());
        const items = menuitems.get();
        for (let item of items) {
            if (!item.visible) {
                continue;
            }
            let xMove = true;
            switch (item.id) {
                case "lang":
                    xMove = this.menuLang(x, y);
                    break;
                case "options":
                    this.textMenu(item, x, y);
                    xMove = true;
                    break;
                case "player":
                    this.textMenu(item, x, y);
                    xMove = true;
                    break;
                // case "tariff":
                //     if (!getState("free")) {
                //         this.textMenu(item, x, y);
                //         xMove = true;
                //     }
                //     break;
            }
            if (xMove) {
                x += ITEM_POS.w + ITEM_POS.gap;
            }
        }

        this.addChild(this.menuBarContainer);
        // const btn = new MySprite({ x: -40, y: 20, atlas: "atlas", frame: "menu" });
    }

    menuLang(x: number, y: number): boolean {
        const enabledLangs = getState("enabledLangs");

        if (enabledLangs.length > 1) {
            let bg = new PIXI.Sprite(PIXI.Texture.from("base-menu-btn"));
            bg.anchor.set(0.5);
            bg.position.set(x, y);
            bg.name = "lang";
            bg.interactive = true;
            bg.on("pointertap", () => {
                this.menuItemClick("lang", bg);
            });
            this.menuBarContainer.addChild(bg);

            const lang = getState("lang")[this.pid];
            const flag = new PIXI.Sprite(PIXI.Texture.from(`${lang}`));
            flag.name = "flag";
            flag.anchor.set(0.5);
            flag.position.set(0, 0);
            flag.scale.set(0.7);
            bg.addChild(flag);
            return true;
        }
        return false;
    }

    textMenu(item: I_MenuItem, x: number, y: number) {
        const container = new PIXI.Container();

        container.position.set(x, y);
        let bg = new MySprite({ x: 0, y: 0, atlas: "atlas", frame: "base-menu-btn" });
        container.addChild(bg);
        container.name = item.id;
        container.interactive = true;
        // container.visible = false;
        container.on("pointertap", () => {
            this.activeMenu = this.menuBarTextItemClick(item, container);
            this.addChild(this.activeMenu);
        });

        let lang: string = getState("lang")[this.pid];

        const label = item.label ? item.label : {};

        lang = Object.hasOwn(label, lang) ? lang : "en";

        const text = new PIXI.Text(label[lang], new PIXI.TextStyle(MENU_STYLE));
        this.langSensitive.push({ text: text, label: label });
        text.anchor.set(0.5);
        text.position.set(bg.x, bg.y);
        container.addChild(text);
        this.menuBarContainer.addChild(container);
    }

    menuBarTextItemClick(item: I_MenuItem, obj: MySprite | PIXI.Container): OptionsSelector | GameCost {
        let res;
        if (this.activeMenu != null) {
            this.removeChild(this.activeMenu);
        }
        switch (item.id) {
            case "options":
                if (item.children) {
                    res = new OptionsSelector(this.pid, obj.parent.x + obj.x, obj.parent.y + obj.y + 27, item.children, this.menuItemClick);
                }
                break;
            case "tariff":
                res = new GameCost(this.pid, obj.parent.x + obj.x, obj.parent.y + obj.y + 27);
                break;
            case "player":
                if (item.children) {
                    res = new SubMenu(this.pid, obj.parent.x + obj.x, obj.parent.y + obj.y + 27, item.children, this.menuItemClick);
                }
                break;
        }
        this.resetMenubarHideTimer();
        return res;
    }

    menuItemClick = (name: string, obj?: MySprite | PIXI.Container) => {
        console.log(name);
        if (this.activeMenu != null) {
            this.removeChild(this.activeMenu);
        }

        switch (name) {
            case "main":
                this.menuBarContainer.visible = !this.menuBarContainer.visible;
                break;
            case "admin":
                if (this.activeMenu == null) {
                    return;
                }
                this.activeMenu = new Numpad(this.pid, this.activeMenu.x, this.activeMenu.y, "admin", this.callbackFromNumpad);
                this.addChild(this.activeMenu);
                break;
            case "system":
                if (this.activeMenu == null) {
                    return;
                }
                this.activeMenu = new Numpad(this.pid, this.activeMenu.x, this.activeMenu.y, "system", this.callbackFromNumpad);
                this.addChild(this.activeMenu);
                break;
            case "info":
                if (this.activeMenu == null) {
                    return;
                }
                this.activeMenu = new Info(this.pid, this.activeMenu.x, this.activeMenu.y);
                this.addChild(this.activeMenu);
                break;
            case "volume":
                if (this.activeMenu == null) {
                    return;
                }
                this.activeMenu = new Volume(this.pid, this.activeMenu.x, this.activeMenu.y, this.resetMenubarHideTimer);
                this.addChild(this.activeMenu);
                break;
            case "lang":
                if (obj) {
                    this.activeMenu = new LangSelector(this.pid, obj.parent.x + obj.x, obj.parent.y + obj.y + 27);
                    this.addChild(this.activeMenu);
                }
                break;
            case "tariff":
                showWarning("tariff", { player: this.pid, message: "", delay: 0 });
                break;

            case "update":
                table.send({ cmd: "checkUpdate" });
                break;
            case "exit":
                table.send({ cmd: "exit" });
                break;
            case "reset":
                table.send({ cmd: "resetStore" });
                break;
            case "adminWifi":
                table.send({ cmd: "showWifi" });
                break;
            case "adb":
                table.send({ cmd: "startADB" });
                break;

            case "clearChromeCache":
                if (window.navigator.onLine) {
                    table.send({ cmd: "clearChromeCache" });
                    if ("caches" in window) {
                        caches.keys().then((names) => {
                            names.forEach(async (name) => {
                                await caches.delete(name);
                            });
                        });
                        window.location.reload();
                    }
                }

                break;
            case "lobbyReload":
                if (window.navigator.onLine) {
                    table.send({ cmd: "deleteSession", key: "loginData" });
                    // window.location.reload();
                    forceReload("lobby");
                }
                // window.location.reload();
                break;
            case "gameCoupon":
                if (this.activeMenu == null) {
                    return;
                }
                this.activeMenu = new Numpad(this.pid, this.activeMenu.x, this.activeMenu.y, "gameCoupon", this.callbackFromNumpad);
                this.addChild(this.activeMenu);
                break;

            // case "setHome":
            //     table.send({ cmd: "runCommand", value: "cmd package set-home-activity com.touchgametable.tgtrunner/.MainActivity" }, (response) => {
            //         table.send({ cmd: "runCommand", value: "am start -n com.touchgametable.tgtrunner/.MainActivity" });
            //     });
            //     break;
        }
        this.resetMenubarHideTimer();
    };

    resetMenubarHideTimer = () => {
        window.clearTimeout(this.hideTimer);
        if (this.menuBarContainer.visible) {
            this.hideTimer = window.setTimeout(() => {
                this.menuBarContainer.visible = false;
                if (this.activeMenu != null) {
                    this.removeChild(this.activeMenu);
                }
            }, 10000);
        }
    };

    callbackFromNumpad = async (senderId: string, code: string) => {
        const showSubMenu = (menu) => {
            if (this.activeMenu == null) {
                return;
            }
            this.removeChild(this.activeMenu);
            const systemItems = this.getMenuItem(menu, menuitems.get(), []);
            this.activeMenu = new SubMenu(this.pid, this.activeMenu.x, this.activeMenu.y, systemItems, this.menuItemClick);
            this.addChild(this.activeMenu);
        };

        if (code == null) {
            this.resetMenubarHideTimer();
            return;
        }
        console.log(senderId, code);
        if (senderId == "gameCoupon") {
            const response = await http(null, `/lobby/usegamecoupon/${getState("tid")}/${code}`, "GET");
            if (response.ack == "ok") {
                setState("gameTime", response.gameTime);

                if (this.activeMenu != null) {
                    this.removeChild(this.activeMenu);
                }
            } else {
                showWarning("message", { delay: 3000, player: this.pid, message: getText("invalidCoupon") });
                this.resetMenubarHideTimer();
            }
        }
        if (senderId == "admin" && code == getState("adminPinCode")) {
            showSubMenu("admin");
            return;
        }
        if (senderId == "admin" || senderId == "system") {
            if (!window.navigator.onLine) {
                if (senderId == "system") {
                    table.send({ cmd: "checkMasterCode", code: code }, () => {
                        showSubMenu("system");
                    });
                }
                return;
            }
            const response = await http(null, `/lobby/adminlogin/${getState("tid")}/${code}`, "GET");
            switch (response.valid) {
                case 1:
                    showSubMenu("admin");
                    break;
                case 2:
                    showSubMenu("system");
                    break;
            }
        }
    };

    changeLang = (lang, player) => {
        for (let child of this.langSensitive) {
            if (player == this.pid) {
                lang = Object.hasOwn(child.label, lang) ? lang : "en";
                child.text.text = child.label[lang];
                this.activeLang = lang;
            }
        }
        if (this.activeMenu && this.activeMenu["changeLang"]) {
            this.activeMenu["changeLang"](lang, player);
        }
    };

    getLang = (): string => {
        return this.activeLang;
    };

    getMenuItem = (id: string, items: I_MenuItem[], result: I_MenuItem[]): I_MenuItem[] => {
        for (let item of items) {
            if (item.id == id && item.children) {
                result = item.children;
                break;
            } else if (item.children) {
                result = this.getMenuItem(id, item.children, result);
            }
        }
        return result;
    };

    langChanged = (lang: string) => {
        const langBar: PIXI.Sprite = this.menuBarContainer.getChildByName("lang") as PIXI.Sprite;
        const flag = langBar.getChildByName("flag") as PIXI.Sprite;
        flag.texture = PIXI.Texture.from(`${lang}`);
    };
}

import * as PIXI from "pixi.js";

import { resize } from "../common/resize";
import * as BaseState from "../store/base-state";
import Carousel from "./carousel";
import Arrow from "./arrows";

import sound from "../modules/sound";

import * as table from "../modules/table";

import MenuControl from "../menu/menu-control";
import { gameStarted, exit } from "../common/timeout";
import { setOfflineMessage } from "../common/online-offline";
import { getState, addListener } from "../store/base-state";
import { showWarning } from "../components/warning/warning";
import { getLang, getText } from "../store/lang";
import * as gameTime from "./game_time";
import { showMessage } from "@/components/message";
import Player from "./Player";
import { IArrow, IAction, emitter } from "@/modules/event";
import { tariff } from "@/components/warning/tariff";

export default class Game extends PIXI.Container {
    static instance: Game;
    scrollActive: boolean = false;
    players: Map<number, Player> = new Map();

    constructor() {
        super();
        Game.instance = this;
        this.name = "gameControl";
        emitter.on("action", this.eventAction);
        emitter.on("arrow", this.eventArrow);
        this.create();
        if (!getState("free")) {
            gameTime.init();
        }
        // this.load();
    }

    eventAction = (event: IAction) => {
        switch (event.action) {
            case "langChanged":
                this.langChanged(event.data.lang, event.data.pid);
                break;
            case "free":
                gameTime.setVisible(!event.data.free);
                break;
            case "gameTime":
                gameTime.refreshGameTime(event.data.gameTime);
                break;
            case "gameCouponEnabled":
                break;
            case "startGame":
                this.gameStart(event.data.game, event.data.pid);
                break;
        }
    };
    clean() {
        for (let p of this.players.values()) {
            p.clean();
        }
    }

    create = () => {
        // if (!Loader.shared.resources.atlas) {

        // }

        gameStarted();
        setOfflineMessage();

        if (PIXI.Cache.has("bg")) {
            const bgTexture = PIXI.Assets.get("bg");
            const bg = new PIXI.Sprite(bgTexture);
            bg.anchor.set(0.5);
            this.addChildAt(bg, 0);
        }

        for (let i = 0; i < 4; ++i) {
            const player = new Player(i);
            this.players.set(i, player);
            this.addChild(player);
        }
        if (window.pixiApp) {
            window.pixiApp.stage.addChild(this);
        }

        resize();
        (async () => {
            await this.loadMoreCards();

            if (!getState("free")) {
                gameTime.setVisible(true);
            }
            // budget < 3min
            if (!BaseState.canPlay()) {
                showWarning("budget", { message: getText("noBudget"), delay: 0, player: 2 });
            } else if (!getState("free")) {
                this.showTariff(2);
            }
            if (!window.navigator.onLine) {
                showMessage("Sorry.\n Internet connection has gone.");
            }
        })();
    };

    async loadMoreCards() {
        for (let p of this.players.values()) {
            p?.carousel.moreCard();
        }
    }

    gameStart(gameName: string, player: number) {
        if (!window.navigator.onLine) {
            showMessage("Sorry.\n Internet connection has gone.", 2000, player);
            return;
        }
        if (this.showTariff(player)) return;

        const gameEndpoint = getState("gameEndpoint");

        // const path = gameEndpoint ? `${gameEndpoint}` : "";
        const path = "https://tgt2test.touchgametable.com";

        table.send({ cmd: "startGame", game: gameName }, () => {
            const parameters = `${path}/${gameName}/index.html?tid=${BaseState.getState("tid")}&lang=${BaseState.getState("lang")[player]}`;

            window.setTimeout(() => {
                window.open(`${parameters}`, "_self");
            }, 0);
        });

        // router.push(`/game/${gameName}`);
    }

    eventArrow = (event: IArrow) => {
        // this.showTariff(player);
        const player = this.players.get(event.pid);
        if (event.direction == "left") {
            player?.carousel.slideLeft();
        } else {
            player?.carousel.slideRight();
        }
    };

    showTariff(player): boolean {
        if (!getState("free")) {
            if (getState("gameTime") < 10) {
                // TODO show not enough time
                // tariff.warning();

                showWarning("tariff", { player: player, delay: 0, message: "" });
                return true;
            }
        }
        return false;
    }

    eventCenter = () => {};

    eventAdminMenu = (player: number, menu: string) => {
        console.log(player, menu);
        switch (menu) {
            case "wifi":
                table.send({ cmd: "showWifi" }, () => {});
                break;
        }
    };

    langChanged = (lang: string, pid: number) => {
        const langs = BaseState.getState("lang");
        langs[pid] = lang;

        BaseState.setState("lang", langs);
        localStorage.setItem("lang", JSON.stringify(langs));
        const p = this.players.get(pid);
        p?.menuControl.langChanged(lang);
        p?.carousel.changeLang(lang);
        tariff.changeLang(pid);
    };
}

import mitt, { Emitter } from "mitt";

export interface I_ToInterface {
    action: string;
    player: number | number[];
    textId?: string;
    text?: string;
    point?: number;
    data?: any;
    params?: any[];
    flash?: number;
    duration?: number;
}

export interface I_FromInterface {
    action: string;
    player: number;
    textId?: string;
    point?: number;
    data?: any;
}

export interface I_Menu {
    action: string;
    player: number;
}

export interface I_PlayerButton {
    action: string;
    player: number;
}

export interface IArrow {
    direction: TArrow;
    pid: number;
}

export interface IAction {
    action: TAction;
    data: any;
}

type TAction = "gameCouponEnabled" | "free" | "langChanged" | "gameTime" | "startGame";

type TArrow = "right" | "left";

type Events = {
    arrow: IArrow;
    action: IAction;
};

export const emitter: Emitter<Events> = mitt<Events>();

// emitter.on("*", (event: any, data: any) => {
//     log.info("+++++++++++++++++++++++");
//     log.info("EVENT");
//     log.info(event);
//     log.info(data);
//     log.info("======================");
// });

export const getOS = () => {
    if (/Windows NT/.test(window.navigator.userAgent)) {
        return "windows";
    }
    if (/Android/.test(window.navigator.userAgent)) {
        return "android";
    }

    return "ios";
};

export function timePadding(value: number) {
    return value < 10 ? `0${value}` : `${value}`;
}

export function secToHours(sec: number): string {
    const h = Math.floor(sec / 3600);
    let left = sec % 3600;
    const min = Math.floor(left / 60);
    return `${h}h:${timePadding(min)}min`;
}

export function secToMin(sec: number): string {
    let minutes = Math.floor(sec / 60);
    let leftSec = sec % 60;
    return `${minutes} min`;
}

export function formatGameTime(sec: number): string {
    const h = String(Math.floor(sec / 3600));
    let left = sec % 3600;

    const min = String(Math.floor(left / 60));
    const s = String(left % 60);

    return `${h.padStart(2, "0")}:${min.padStart(2, "0")}:${s.padStart(2, "0")}`;
}

export function notIn(key: any, list: any[]): boolean {
    for (let i = 0; i < list.length; i++) {
        if (list[i] == key) {
            return false;
        }
    }
    return true;
}

import * as PIXI from "pixi.js";
import MySprite from "../components/sprite";
import { getState } from "../store/base-state";
import { I_MenuItem } from "../store/interfaces";
import { menuitems } from "./menu_items";

const MENU_STYLE: Partial<PIXI.ITextStyle> = {
    fontFamily: "Roboto Slab",
    fontSize: 24,
    fill: "#000000",
    align: "left",
};

export default class OptionsSelector extends PIXI.Container {
    constructor(public player: number, x: number, y: number, items: I_MenuItem[], public callback: (itemId: string) => void) {
        super();
        this.position.set(x, y);
        this.create(items);
    }

    create(items: I_MenuItem[]) {
        const bg = new MySprite({ x: 0, y: 0, atlas: "atlas", frame: "menu-panel1", anchorY: 0 });
        bg.interactive = true;
        this.addChild(bg);
        let x = -80;
        let y = 30;

        for (let item of items) {
            if (!item.visible) {
                continue;
            }
            if (item.id == "tariff" && getState("free")) {
                continue;
            }
            const lang: string = getState("lang")[this.player];
            const label = menuitems.getLabel(item, lang);
            const text = new PIXI.Text(label, new PIXI.TextStyle(MENU_STYLE));
            text.position.set(x, y);
            text.eventMode = "static";
            this.addChild(text);
            text.on("pointertap", () => {
                this.eventClickMenuItem(item.id);
            });

            y += 40;
            if (item.id == "system") {
                text.alpha = 0.01;
            }
        }
        // const style = Object.assign({}, MENU_STYLE, { fontSize: 12, align: "right" });
        // const version = new PIXI.Text(`v${getState("environmentVersion")}/${getState("version")}`, new PIXI.TextStyle(style));
        // version.position.set(40, bg.height - 30);
        // this.addChild(version);
    }

    eventClickMenuItem = (id: string) => {
        this.callback(id);
    };
}

import * as PIXI from "pixi.js";
import MySprite from "../components/sprite";
import { getState } from "../store/base-state";
import { I_MenuItem } from "../store/interfaces";

const MENU_STYLE: Partial<PIXI.ITextStyle> = {
    fontFamily: "Roboto Slab",
    fontSize: 24,
    fill: "#000000",
    align: "left",
};

export default class GameCost extends PIXI.Container {
    constructor(public player: number, x: number, y: number) {
        super();
        this.position.set(x, y);
    }

    create(items: I_MenuItem[]) {
        const bg = new MySprite({ x: 0, y: 0, atlas: "atlas", frame: "menu-panel1", anchorY: 0 });
        this.addChild(bg);
        let x = -70;
        let y = 30;

        for (let item of items) {
            if (item.label) {
                const text = new PIXI.Text(item.label[getState("lang")[this.player]], new PIXI.TextStyle(MENU_STYLE));
                text.position.set(x, y);
                text.interactive = true;
                this.addChild(text);
                text.on("pointertap", () => {});

                y += 40;
            }
        }
        const style = Object.assign({}, MENU_STYLE, { fontSize: 12, align: "right" });
        const ip = new PIXI.Text(`${getState("ip")}`, new PIXI.TextStyle(style));
        ip.position.set(15, bg.height - 30);
        this.addChild(ip);
    }
}

import * as PIXI from "pixi.js";
import { getState } from "../store/base-state";

const INFO_STYLE: Partial<PIXI.ITextStyle> = {
    fontFamily: "Roboto Slab",
    fontSize: 30,
    wordWrap: true,
    wordWrapWidth: 600,
    fill: "#ffffff",
    align: "center",
};

let container: PIXI.Container;
let timeout: number;

function init() {
    container = new PIXI.Container();
    const bg = new PIXI.Sprite(PIXI.Texture.from("message"));
    bg.anchor.set(0.5);

    bg.on("pointertap", () => {
        container.visible = false;
        window.clearTimeout(timeout);
    });
    container.addChild(bg);
    const text = new PIXI.Text("", INFO_STYLE);
    text.name = "message";
    text.anchor.set(0.5);
    text.y = 50;
    container.addChild(text);

    container.position.set(window.gameInfo.width * 0.5, window.gameInfo.height * 0.5);
    window.pixiApp?.stage.addChild(container);
}

export function showMessage(message: string, delay = 0, player: number = 2) {
    if (!container) {
        init();
    }
    container.angle = getState("angle")[player];
    container.scale.set(0.8);
    container.visible = true;
    (<PIXI.Text>container.getChildByName("message")).text = message;
    if (delay > 0) {
        timeout = window.setTimeout(() => {
            hideMessage();
        }, delay);
    }
}

export function hideMessage() {
    if (container) {
        container.removeChildren();
        container.destroy();
    }
}

function budgetRefresh(budget) {
    if (container) {
    }
}

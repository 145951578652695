import * as PIXI from "pixi.js";
import { scaleAnim } from "@/modules/tween-handler";
import { getState, emitEvent } from "../store/base-state";
import sound from "../modules/sound";
import { emitter } from "@/modules/event";

export default class LangSelector extends PIXI.Container {
    constructor(public player: number, x: number, y: number) {
        super();
        this.position.set(x, y);
        this.create();
    }

    create() {
        const bg = new PIXI.Sprite(PIXI.Texture.from("menu-panel1"));
        bg.anchor.set(0.5, 0);
        bg.eventMode = "static";
        this.addChild(bg);
        this.addFlags(bg);
    }

    addFlags(bg: PIXI.Sprite) {
        const enabledLangs = getState("enabledLangs");
        let x = 30;

        const step = bg.height / (enabledLangs.length + 1);
        let y = step;

        for (let i = 0; i < enabledLangs.length; i++) {
            // const flag = new MySprite({ x: x, y: y, atlas: "atlas", frame: `${enabledLangs[i]}-flag` }).setName(`${enabledLangs[i]}-flag`);
            // flag.scale.set(0.7);
            const flag = new PIXI.Sprite(PIXI.Texture.from(`${enabledLangs[i]}`));
            flag.anchor.set(0.5);
            flag.position.set(0, y);
            flag.name = enabledLangs[i];
            flag.eventMode = "static";
            this.addChild(flag);
            flag.on("pointertap", () => {
                this.setActiveLang(enabledLangs[i]);
                scaleAnim(flag);
            });
            this.addChild(flag);
            y += step;
            // x *= -1;
        }
        this.setActiveLang(getState("lang")[this.player]);
    }

    eventFlag(flag: string) {}

    setActiveLang = (selectedLang: string) => {
        emitter.emit("action", { action: "langChanged", data: { lang: selectedLang, pid: this.player } });
    };
}

import { formatGameTime, secToMin } from "../tools/utils";
import { getState, addListener, removeListener } from "../store/base-state";
import * as PIXI from "pixi.js";
import * as utils from "@/modules/utils";
import Game from "./game-control";
import { IAction, emitter } from "@/modules/event";

const INFO_STYLE: Partial<PIXI.ITextStyle> = {
    fontFamily: "Digit",
    fontSize: 20,
    fill: "#ffffff",
    align: "left",
};

const FILL = ["#0000ff", "#00ff00", "#ff0000", "#ffff00"];

const POS = { x: 120, y: 30 };

const clocks: PIXI.Text[] = [];

export function init() {
    const gameTime = getState("gameTime");
    const gtFormat = formatGameTime(gameTime);
    for (let i = 0; i < 4; i++) {
        const style = new PIXI.TextStyle(INFO_STYLE);
        style.fill = FILL[i];
        let text = new PIXI.Text(gtFormat, style);
        text.name = "gameTime";

        const pos = utils.getObjectBoardPosByEdge(i, POS.x, POS.y);
        text.position.set(pos.x, pos.y);
        text.angle = pos.angle;
        text.anchor.set(0, 0);
        Game.instance.addChild(text);
        clocks.push(text);
    }
}

export function setVisible(visible: boolean) {
    if (clocks.length == 0) {
        init();
    }
    clocks.forEach((item: PIXI.Text) => {
        item.visible = visible;
    });
}

export function hide() {
    setVisible(false);
}

export function refreshGameTime(gameTime: number) {
    if (clocks.length == 0) {
        init();
    }

    clocks.forEach((item: PIXI.Text) => {
        item.text = formatGameTime(gameTime);
        item.visible = true;
    });
}

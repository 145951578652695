import * as BaseState from "../store/base-state";

export default async function http(data, url, method = "POST") {
    const fullUrl = "/api/v1" + url;
    if (data != null) {
        data.tz = BaseState.getState("tz");
        data.gameName = "lobby";

        if (url.search("login") != -1) {
            data.hid = BaseState.getState("hid");
        } else {
            data.tid = BaseState.getState("tid");
        }
    }

    let params = {
        method: method,
        // headers: {
        //     authorization: `Bearer ${BaseState.getState("token")}`,
        // },
    };

    if (data != null) {
        params["body"] = JSON.stringify(data);
    }

    try {
        const response = await fetch(fullUrl, params);

        if (response.status == 200) {
            let data = await response.json();
            return data;
        } else {
            switch (response.status) {
                case 404: // no page
                case 405: // method not allowed
                    return { ack: "serverError", status: response.status };
                default:
                    // server is down
                    throw "no internet";
            }
        }
    } catch (e) {
        return { ack: "noInternet" };
    }
}

export const forceReload = (gameName: string) => {
    const regex = new RegExp(gameName);
    caches.keys().then((names) => {
        names.forEach(async (name) => {
            if (regex.test(name)) {
                await caches.delete(name);
            }
        });
    });
    window.location.reload();
};

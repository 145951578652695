export enum GAME_STATE {
    IDLE = 0,
    LOGIN,
    DISABLED,
}

interface I_GameInfo {
    [key: string]: { name: string; info: string; valid: boolean };
}

export interface I_GameData {
    gameName: string;
    playTime: number;
    info: I_GameInfo;
}

interface I_GameState {
    gameData: I_GameData | null;
}

type GameStateKeys = keyof I_GameState;

const state: I_GameState = {
    gameData: null,
};

export function setState<K extends GameStateKeys>(key: K, value: any) {
    return (state[key] = value);
}

export function getState<K extends GameStateKeys>(key: K): any {
    return state[key];
}

import * as PIXI from "pixi.js";

const INFO_STYLE: Partial<PIXI.ITextStyle> = {
    fontFamily: "Roboto Slab",
    fontSize: 30,
    wordWrap: true,
    wordWrapWidth: 600,
    fill: "#ffffff",
    align: "center",
};

export function getMessage(mess: string): PIXI.Text {
    // const container = new PIXI.Container();
    const text = new PIXI.Text(mess, INFO_STYLE);
    text.name = "message";
    text.anchor.set(0.5);
    text.y = 50;
    // container.addChild(text);
    return text;
}

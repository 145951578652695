import * as PIXI from "pixi.js";
import MySprite from "../components/sprite";
import { getState, setState } from "../store/base-state";
import sound from "../modules/sound";
import * as table from "@/modules/table";

export default class Volume extends PIXI.Container {
    lineArc: PIXI.Graphics;
    volume: number;

    constructor(public player: number, x: number, y: number, private callback: () => void) {
        super();
        this.position.set(x, y);

        this.volume = getState("volume");
        const panel = new MySprite({ x: 0, y: 0, atlas: "atlas", frame: "menu-panel1", anchorY: 0 });
        this.addChild(panel);

        const bg = new MySprite({ x: 0, y: 145, atlas: "atlas", frame: "volume-back" });
        this.addChild(bg);

        const front = new MySprite({ x: 0, y: 145, atlas: "atlas", frame: "volume-front" });
        this.addChild(front);

        this.lineArc = new PIXI.Graphics();
        // const sprite = PIXI.Sprite.from(PIXI.Loader.shared.resources.atlas.textures["volume-front"]);
        // const lineTexture = PIXI.Loader.shared.resources.atlas.textures["volume-front"];
        // this.lineArc.lineTextureStyle({ width: 20, texture: lineTexture });
        this.lineArc.lineStyle(40, 0xff0000, 1);
        // this.lineArc.beginTextureFill({ texture: sprite.texture });
        this.lineArc.arc(0, bg.y, 70, 1.5 * Math.PI, 1.5 * Math.PI + (Math.PI / 180) * 24 * this.volume);
        // this.lineArc.endFill();
        this.addChild(this.lineArc);
        front.mask = this.lineArc;

        const up = new MySprite({ x: 0, y: bg.y - 29, atlas: "atlas", frame: "volume-up" });
        up.interactive = true;
        up.on("pointertap", () => {
            this.setVolume(1);
        });

        this.addChild(up);

        const down = new MySprite({ x: 0, y: bg.y + 29, atlas: "atlas", frame: "volume-down" });
        down.interactive = true;
        down.on("pointertap", () => {
            this.setVolume(-1);
        });

        this.addChild(down);
    }

    setVolume(dir: number) {
        this.callback();
        this.volume += dir;
        if (this.volume > 15) {
            this.volume = 15;
            return;
        }
        if (this.volume < 0) {
            this.volume = 0;
            return;
        }

        table.send({ cmd: "setVolume", volume: this.volume }, (response) => {
            this.lineArc.clear();
            this.lineArc.lineStyle(40, 0xff0000, 1);
            this.lineArc.arc(0, 145, 70, 1.5 * Math.PI, 1.5 * Math.PI + (Math.PI / 180) * 24 * this.volume);
            setState("volume", this.volume);
            getSound().play("gameStart");
        });
    }
}

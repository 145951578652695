type T_Params = {
    method: string;
    body?: any;
    headers?: Headers;
};

export default async function useFetch(url, params: T_Params): Promise<any> {
    if (params.body) {
        params.body = JSON.stringify(params.body);
        params.headers = new Headers({ "content-type": "application/json" });
    }

    try {
        const res = await fetch(url, params);

        if (res.status == 401) {
            // router.replace("/login");
            return;
        }
        console.log(res);
        if (res.ok) {
            try {
                const data = await res.json();
                return data;
            } catch (e) {}
        } else {
            const message = await res.text();
            // Notify.create({ position: "top", type: "negative", message: message });
        }
    } catch (err: any) {
        // Notify.create({ position: "top", type: "negative", message: err.message });
    }
}

import * as table from "@/modules/table";
import * as BaseState from "@/store/base-state";
import { lobbyLogin } from "./lobby-login";

export function connectTable() {
    table.connect(tableConnectionState);
    table.disconnect(() => {});
}

function tableConnectionState(response: any) {
    table.send({ cmd: "login", state: "lobby", socketName: "game", game: "lobby" });
    // if (response.tid) {
    //     BaseState.setState("gameTime", response.gameTime);
    //     BaseState.setState("timeBudget", response.budget);
    //     BaseState.setState("free", response.free);
    //     BaseState.setState("tid", response.tid);
    //     BaseState.setState("hid", response.hid);
    //     BaseState.setState("tableServerVersion", response.version);
    //     if (response.loginData) {
    //         // gameinfo.setGameInfo(response.I_GameInfo);
    //         setLoginData(response.loginData, false);
    //         return;
    //     } else {
    //         lobbyLogin();
    //     }
    // }

    table.send({ cmd: "getTid" }, (response) => {
        BaseState.setState("hid", response.tid);
        lobbyLogin();
    });

    table.send({ cmd: "getVolume" }, (response) => {
        BaseState.setState("volume", response.volume);
    });
    table.send({ cmd: "getVersion" }, (response) => {
        BaseState.setState("environmentVersion", response.version);
        BaseState.setState("OS", response.os);
        // router.replace("/login");
    });
    table.send({ cmd: "getIp" }, (response) => {
        try {
            let ip = null;
            if (BaseState.getState("platform") == "windows") {
                ip = response.value;
            } else {
                const reg = response.value.match(/src ([0-9]{3}\.[0-9]{3}\.[0-9]{1,3}\.[0-9]{1,3})/);
                if (reg.length > 1) {
                    ip = reg[1];
                }
            }
            if (ip) {
                BaseState.setState("ip", ip);
            }
        } catch (e) {}
    });
}

import * as PIXI from "pixi.js";
import * as utils from "@/modules/utils";
import * as BaseState from "../store/base-state";
import Carousel from "./carousel";
import Arrow from "./arrows";
import MenuControl from "../menu/menu-control";
import sound from "@/modules/sound";

const OFFSET = { x: 0, y: -280 };

export default class Player extends PIXI.Container {
    menuControl: MenuControl;
    arrow: Arrow;
    carousel: Carousel;

    constructor(pid: number) {
        super();

        let pos = utils.getObjectBoardPosXCenterYEdge(pid, OFFSET.x, OFFSET.y);
        this.position.set(pos.x, pos.y);
        this.angle = BaseState.getState("angle")[pid];

        this.carousel = new Carousel(pid);
        this.carousel.name = `carousel${pid}`;
        this.addChild(this.carousel);
        this.carousel.setMask();
        this.arrow = new Arrow(this, pid);

        // new Numpad(this, i);
        this.menuControl = new MenuControl(pid);
        this.addChild(this.menuControl);
    }

    clean() {
        this.carousel.clean();
    }
}

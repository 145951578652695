import { getState } from "./base-state";

export interface IGameInfoLang {
    [key: string]: { name: string; info: string };
}

export interface IGameInfo {
    game: string;
    gameInfo: IGameInfoLang;
}

let gameInfo: IGameInfo[] = [];

export const setGameInfo = (data: IGameInfo[]) => {
    gameInfo = [...data];
};

export function getInfo(player: number, game: string): { name: string; info: string } {
    for (let item of gameInfo) {
        if (item.game == game) {
            const lang = getState("lang")[player];
            return item.gameInfo[lang];
        }
    }
    return { name: "", info: "" };
}

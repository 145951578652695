import Game from "./game-control";
import * as PIXI from "pixi.js";
import * as BaseState from "../store/base-state";
import * as utils from "@/modules/utils";
import Carousel from "./carousel";
import Player from "./Player";
import { emitter } from "@/modules/event";
import { scaleAnim } from "@/modules/tween-handler";

const Y = 120;
const X = 420;
const OFFSET = { x: 0, y: 150 };

export default class Arrow {
    static leftArrows: PIXI.Sprite[] = [];
    static rightArrows: PIXI.Sprite[] = [];

    constructor(public player: Player, public pid: number) {
        const leftArrow = new PIXI.Sprite(PIXI.Texture.from("arrow"));

        leftArrow.name = "left";
        leftArrow.position.set(-X, OFFSET.y);
        leftArrow.anchor.set(0.5);
        leftArrow.tint = BaseState.getTableColor(this.pid);
        leftArrow.eventMode = "static";
        Arrow.setArrowAngle(leftArrow, pid);

        leftArrow.on("pointertap", () => {
            // this.game.eventArrow("left", this.pid);
            emitter.emit("arrow", { direction: "left", pid: pid });
            scaleAnim(leftArrow);
        });
        player.addChild(leftArrow);

        let rightArrow = new PIXI.Sprite(PIXI.Texture.from("arrow"));

        rightArrow.name = "right";
        rightArrow.position.set(X, OFFSET.y);
        rightArrow.anchor.set(0.5);

        rightArrow.tint = BaseState.getTableColor(this.pid);
        rightArrow.eventMode = "static";
        Arrow.setArrowAngle(rightArrow, pid);
        rightArrow.on("pointertap", () => {
            // this.game.eventArrow("right", this.pid);
            emitter.emit("arrow", { direction: "right", pid: pid });
            scaleAnim(rightArrow);
        });
        player.addChild(rightArrow);
    }

    static setArrowAngle(sprite: PIXI.Sprite, pid: number) {
        if (sprite.name == "left") {
            sprite.scale.x *= -1;
        }
    }
}

import { Container, TextStyle, Text, ITextStyle } from "pixi.js";

import MySprite from "../sprite";

const STYLE: Partial<ITextStyle> = {
    fontFamily: "Roboto Slab",
    fontSize: 24,
    fill: "#000000",
    align: "center",
};

export default class Button extends Container {
    constructor(label: string) {
        super();
        this.addChild(new MySprite({ x: 0, y: 0, atlas: "atlas", frame: "numpad-btn-active" }).setName("active").setVisible(false));
        const sprite = new MySprite({ x: 0, y: 0, atlas: "atlas", frame: "numpad-btn" });
        this.addChild(sprite);

        const text = new Text(label, new TextStyle(STYLE));
        text.anchor.set(0.5);
        text.position.set(0, 0);
        this.addChild(text);
        this.name = label;
        this.interactive = true;
    }

    setActive(active: boolean) {
        (<MySprite>this.getChildByName("active")).setVisible(active);
    }
}

/**
 * Created by janos on 2017.07.15..
 */

/**
 *
 */
export default class RandomDataGenerator {
    c = 1;
    s0 = 0;
    s1 = 0;
    s2 = 0;

    /**
     *
     * @param seeds {any}
     */
    constructor(seeds?: any) {
        if (seeds === undefined) {
            seeds = [Date.now(), 12, 78956];
        }

        if (typeof seeds === "string") {
            this.state(seeds);
        } else {
            this.sow(seeds);
        }
    }

    /**
     *
     * @returns {number|*|Number}
     */
    rnd() {
        let t = 2091639 * this.s0 + this.c * 2.3283064365386963e-10; // 2^-32
        this.c = t | 0;
        this.s0 = this.s1;
        this.s1 = this.s2;
        this.s2 = t - this.c;

        return this.s2;
    }

    /**
     * Returns a random integer between 0 and 2^32.
     * @returns {number}
     */
    integer() {
        return this.rnd.apply(this) * 0x100000000; // 2^32
    }

    /**
     *
     * @param seeds
     */
    sow(seeds) {
        // Always reset to default seed
        this.s0 = this.hash(" ");
        this.s1 = this.hash(this.s0);
        this.s2 = this.hash(this.s1);
        this.c = 1;

        if (!seeds) {
            return;
        }

        // Apply any seeds
        for (let i = 0; i < seeds.length && seeds[i] != null; i++) {
            let seed = seeds[i];

            this.s0 -= this.hash(seed);
            this.s0 += ~~(this.s0 < 0);
            this.s1 -= this.hash(seed);
            this.s1 += ~~(this.s1 < 0);
            this.s2 -= this.hash(seed);
            this.s2 += ~~(this.s2 < 0);
        }
    }

    /**
     *
     * @param data {number}
     * @returns {number}
     */
    hash(data) {
        let h, i, n;

        n = 0xefc8249d;
        data = data.toString();

        for (i = 0; i < data.length; i++) {
            n += data.charCodeAt(i);
            h = 0.02519603282416938 * n;
            n = h >>> 0;
            h -= n;
            h *= n;
            n = h >>> 0;
            h -= n;
            n += h * 0x100000000; // 2^32
        }
        return (n >>> 0) * 2.3283064365386963e-10; // 2^-32
    }

    /**
     *
     * @param state {string}
     * @returns {string}
     */
    state(state) {
        if (typeof state === "string" && state.match(/^!rnd/)) {
            let s = state.split(",");

            this.c = parseFloat(s[1]);
            this.s0 = parseFloat(s[2]);
            this.s1 = parseFloat(s[3]);
            this.s2 = parseFloat(s[4]);
        }

        return ["!rnd", this.c, this.s0, this.s1, this.s2].join(",");
    }

    /**
     * Returns a random real number between 0 and 1.
     * @return {number} A random real number between 0 and 1.
     */
    frac() {
        return this.rnd.apply(this) + ((this.rnd.apply(this) * 0x200000) | 0) * 1.1102230246251565e-16; // 2^-53
    }

    /**
     * Returns a random real number between 0 and 2^32.
     * @return {number} A random real number between 0 and 2^32.
     */
    real() {
        return this.integer() + this.frac();
    }

    /**
     * Returns a random integer between and including min and max.
     * @param {number} min - The minimum value in the range.
     * @param {number} max - The maximum value in the range.
     * @return {number} A random number between min and max.
     */
    integerInRange(min, max) {
        return Math.floor(this.realInRange(0, max - min + 1) + min);
    }

    /**
     * Returns a random integer between and including min and max.
     * This method is an alias for RandomDataGenerator.integerInRange.
     * @param {number} min - The minimum value in the range.
     * @param {number} max - The maximum value in the range.
     * @return {number} A random number between min and max.
     */
    between(min, max) {
        return this.integerInRange(min, max);
    }

    /**
     * Returns a random real number between min and max.
     * @param {number} min - The minimum value in the range.
     * @param {number} max - The maximum value in the range.
     * @return {number} A random number between min and max.
     */
    realInRange(min, max) {
        return this.frac() * (max - min) + min;
    }

    /**
     * Returns a random real number between -1 and 1.
     *
     * @method Phaser.RandomDataGenerator#normal
     * @return {number} A random real number between -1 and 1.
     */
    normal() {
        return 1 - 2 * this.frac();
    }

    /**
     * Returns a valid RFC4122 version4 ID hex string from https://gist.github.com/1308368
     * @return {string} A valid RFC4122 version4 ID hex string
     */
    // uuid(): string {
    // let a = '';
    // let b = '';
    // for (b = a = ''; a++ < 36; b +=~a % 5 | a * 3&4 ? (a^15 ? 8^this.frac() * (a^20 ? 16 : 4) : 4).toString(16) : '-') {
    // }
    // return b;
    // }

    /**
     * Returns a random member of `array`.
     * @param {Array} ary - An Array to pick a random member of.
     * @return {any} A random member of the array.
     */
    pick(ary) {
        return ary[this.integerInRange(0, ary.length - 1)];
    }

    /**
     * Returns a sign to be used with multiplication operator.
     * @return {number} -1 or +1.
     */
    sign() {
        return this.pick([-1, 1]);
    }

    /**
     * Returns a random member of `array`, favoring the earlier entries.
     * @param {Array} ary - An Array to pick a random member of.
     * @return {any} A random member of the array.
     */
    weightedPick(ary) {
        return ary[~~(Math.pow(this.frac(), 2) * (ary.length - 1) + 0.5)];
    }

    /**
     * Returns a random timestamp between min and max, or between the beginning of 2000 and the end of 2020 if min and max aren't specified.
     * @param {number} min - The minimum value in the range.
     * @param {number} max - The maximum value in the range.
     * @return {number} A random timestamp between min and max.
     */
    timestamp(min, max) {
        return this.realInRange(min || 946684800000, max || 1577862000000);
    }

    /**
     * Returns a random angle between -180 and 180.
     * @return {number} A random number between -180 and 180.
     */
    angle() {
        return this.integerInRange(-180, 180);
    }
}

import * as PIXI from "pixi.js";
import MySprite from "../components/sprite";
import { getState } from "../store/base-state";

const MENU_STYLE: Partial<PIXI.ITextStyle> = {
    fontFamily: "Roboto Slab",
    fontSize: 16,
    fill: "#000000",
    align: "left",
};

export default class Info extends PIXI.Container {
    constructor(public player: number, x: number, y: number) {
        super();
        this.position.set(x, y);
        this.create();
    }

    create() {
        const bg = new MySprite({ x: 0, y: 0, atlas: "atlas", frame: "menu-panel1", anchorY: 0 });
        this.addChild(bg);
        let x = -80;
        let y = 30;
        const yOffset = 30;
        const items = [
            `Hid: ${getState("hid")}`,
            `Tid: ${getState("tid")}`,
            `V.system: ${getState("environmentVersion")}`,
            `V.browser: ${getState("version")}`,
            `IP: ${getState("ip")}`,
        ];

        for (let item of items) {
            let text = new PIXI.Text(item, MENU_STYLE);
            text.position.set(x, y);
            this.addChild(text);
            y += yOffset;
        }
    }
}

import { setState } from "@/store/base-state";

type T_Params = {
    method: string;
    body?: any;
    headers?: Headers;
};

type T_Headers = {
    "content-type": string;
};

export default async function useFetch(url, params: T_Params, json = true): Promise<any> {
    if (params.body && json) {
        params.body = JSON.stringify(params.body);
    }

    params.headers = params.headers ? params.headers : new Headers({ "content-type": "application/json" });
    try {
        const res = await fetch(url, params);

        // Invalid table, not found
        if (res.status == 404) {
            // router.replace("/registration");
            return;
        }
        if (res.status == 403) {
            alert("Table is inactive. Please call the attendent.");
            return;
        }
        console.log(res);
        if (res.ok) {
            try {
                const data = await res.json();
                return data || {};
            } catch (e) {}
        } else {
            const message = await res.text();
            alert(message);
            console.error(message);
        }
    } catch (err: any) {
        console.error(err);
    }
}

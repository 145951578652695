import * as PIXI from "pixi.js";
import * as BaseState from "../store/base-state";
import * as gamestate from "../store/game-state";
import { getLang, getText } from "../store/lang";

import Game from "./game-control";
import { gsap } from "gsap";
import getSound from "../modules/sound";
import { secToHours } from "../tools/utils";
import { emitEvent } from "../store/base-state";
import { emitter } from "@/modules/event";

const WIDTH = 180;
const HEIGHT = 270;
const GAP = 10;

const STYLE: Partial<PIXI.ITextStyle> = {
    fontFamily: "Roboto Slab",
    fontSize: 24,
    wordWrap: true,
    wordWrapWidth: 170,
    fill: "#ffffff",
};

export default class Card extends PIXI.Container {
    front!: PIXI.Container;
    back!: PIXI.Container;
    activeSide!: PIXI.Container;
    gameData!: gamestate.I_GameData;
    enabled: boolean = true;
    noLang!: PIXI.Text;

    constructor(public cardData: gamestate.I_GameData, public pid: number) {
        super();
        this.createFront();
        this.createBack(this.front);
        this.visible = false;
    }

    createFront() {
        let y = 0;
        let x = 0;

        const lang = this.getCardInfoLang();

        this.front = new PIXI.Container();

        // const body = new MySprite({ x: 0, y: y, atlas: "atlas", frame: "card", anchorY: 0 });
        const body = new PIXI.Sprite(PIXI.Texture.from("card"));
        body.y = y;
        body.anchor.set(0.5, 0);
        body.tint = BaseState.getTableColor(this.pid);
        body.name = "cardBody";
        this.front.addChild(body);

        y += 10;
        // const gameIcon = new MySprite({ x: 0, y: y, atlas: "gameIcons", frame: `${this.cardData.gameName}`, anchorY: 0 }).setSize(130);
        const gameIcon = new PIXI.Sprite(PIXI.Texture.from(`${this.cardData.gameName}`));
        gameIcon.y = y;
        gameIcon.anchor.set(0.5, 0);
        gameIcon.width = gameIcon.height = 130;
        gameIcon.name = "gameIcon";
        gameIcon.eventMode = "static";

        gameIcon.on("pointertap", () => {
            getSound().play("blup");
            if (this.isValid()) {
                // this.game.gameStart(this.cardData.gameName, this.pid);
                emitter.emit("action", { action: "startGame", data: { game: this.cardData.gameName, pid: this.pid } });
            } else {
                this.noLang.visible = true;
                window.setTimeout(() => {
                    this.noLang.visible = false;
                }, 3000);
            }
        });
        this.front.addChild(gameIcon);

        this.setValid(lang);

        // let s = Object.assign({}, STYLE, { fontSize: 20, align: "center", strokeThickness: 1, stroke: "#000000" });
        let s: Partial<PIXI.ITextStyle> = { ...STYLE, fontSize: 20, align: "center", strokeThickness: 2, stroke: "#000000" };
        let style = new PIXI.TextStyle(s);

        this.noLang = new PIXI.Text("Not available\n in this\n language.", style);
        this.noLang.anchor.set(0.5);
        this.noLang.position.set(gameIcon.x, gameIcon.y + gameIcon.height * 0.5);
        this.noLang.visible = false;
        this.front.addChild(this.noLang);

        y += gameIcon.height + 15;
        const s2 = Object.assign({}, STYLE, { fontSize: 20, align: "center" });
        style = new PIXI.TextStyle(s2);
        const name: string = <string>this.cardData.info[lang].name;
        if (name.length > 14) {
            style.fontSize = 16;
        }
        const gameName = new PIXI.Text(`${this.cardData.info[lang].name}`, style);
        gameName.anchor.set(0.5);
        gameName.position.set(0, y);
        gameName.name = "gameName";
        this.front.addChild(gameName);

        y += 30;
        const info = new PIXI.Sprite(PIXI.Texture.from("info-icon"));
        info.y = y;
        info.anchor.set(0.5, 0);

        info.name = "info";
        info.eventMode = "static";
        info.on("pointertap", () => {
            this.flipToBack();
            getSound().play("blup");
        });

        this.front.addChild(info);

        y += 50;
        s = Object.assign({}, STYLE, { fontSize: 16, align: "center" });
        style = new PIXI.TextStyle(s);
        const played = new PIXI.Text(getText("playingTime"), style);
        played.anchor.set(0.5);
        played.position.set(0, y);
        played.name = "playingTime";

        this.front.addChild(played);

        y += 20;

        const gameNumber = new PIXI.Text(`${secToHours(this.cardData.playTime)}`, style);
        gameNumber.anchor.set(0.5);
        gameNumber.position.set(0, y);
        gameNumber.name = "gameNumber";

        this.front.position.set(x, 0);
        this.front.pivot.set(1, 0);

        this.front.addChild(gameNumber);
        this.activeSide = this.front;
        this.addChild(this.front);

        // this.front.cacheAsBitmap = true;
    }

    createBack(front: PIXI.Container) {
        const lang = this.getCardInfoLang();

        let y = 0;

        this.back = new PIXI.Container();

        const body = new PIXI.Sprite(PIXI.Texture.from("card"));
        body.y = y;
        body.anchor.set(0.5, 0);

        body.name = "cardBackBody";
        body.eventMode = "static";
        body.on("pointertap", () => {
            this.flipToFront();
            getSound().play("blup");
        });
        this.back.addChild(body);

        let s = Object.assign(STYLE, { fontSize: 14, align: "left" });
        let style = new PIXI.TextStyle(s);
        const info = new PIXI.Text(`${this.cardData.info[lang].info}`, style);
        info.name = "gameinfo";
        info.anchor.set(0, 0);
        info.position.set(body.width * -0.5 + 10, 20);
        this.back.addChild(info);

        this.back.position.set(front.x, front.y);
        this.back.pivot.set(1, 0);
        this.back.visible = false;
        this.addChild(this.back);

        // this.back.cacheAsBitmap = true;
    }

    flipToBack = () => {
        gsap.to(this.front.scale, {
            x: 0,
            y: 1.1,
            duration: 0.1,
            onComplete: () => {
                this.front.visible = false;
                this.back.visible = true;
                this.back.scale.set(0, 1.1);
                gsap.to(this.back.scale, {
                    x: 1,
                    y: 1,
                    duration: 0.1,
                    onComplete: () => {
                        this.activeSide = this.back;
                    },
                });
            },
        });
    };

    flipToFront = () => {
        gsap.to(this.back.scale, {
            x: 0,
            y: 1.1,
            duration: 0.1,
            onComplete: () => {
                this.front.visible = true;
                this.back.visible = false;
                // this.front.scale.set(0, 1.1);
                gsap.to(this.front.scale, {
                    x: 1,
                    y: 1,
                    duration: 0.1,
                    onComplete: () => {
                        this.activeSide = this.front;
                    },
                });
            },
        });
    };

    setLang = (lang: string) => {
        lang = this.getCardInfoLang();
        const gameName = <PIXI.Text>this.front.getChildByName("gameName");
        gameName.text = `${this.cardData.info[lang].name}`;
        if (gameName.text.length > 14) {
            gameName.style.fontSize = 16;
        } else {
            gameName.style.fontSize = 20;
        }

        const playingTime = <PIXI.Text>this.front.getChildByName("playingTime");
        playingTime.text = getText("playingTime");

        this.setTextFontSize(lang);
        this.setValid(lang);
    };

    setTextFontSize(lang: string) {
        const description = <PIXI.Text>this.back.getChildByName("gameinfo");
        description.text = `${this.cardData.info[lang].info}`;

        if (description.text.length > 14) {
            description.style.fontSize = 16;
        } else {
            description.style.fontSize = STYLE.fontSize as number;
        }
    }

    setValid(lang: string) {
        const gameIcon = <PIXI.Sprite>this.front.getChildByName("gameIcon");

        if (this.cardData.info[lang].valid) {
            // gameIcon.interactive = true;
            gameIcon.alpha = 1;
        } else {
            gameIcon.alpha = 0.5;
            // gameIcon.interactive = false;
        }
    }

    isValid(): boolean {
        return this.cardData.info[this.getCardInfoLang()].valid;
    }

    getCardInfoLang = (): string => {
        const lang = BaseState.getState("lang")[this.pid];
        return Object.hasOwn(this.cardData.info, lang) ? lang : "en";
    };
}

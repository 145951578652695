import { showMessage, hideMessage } from "../components/message";

let active = false;

export function setOfflineMessage() {
    if (!active) {
        active = true;
        window.addEventListener("offline", (event) => {
            showMessage("Sorry.\n Internet connection has gone.");
        });

        window.addEventListener("online", (event) => {
            hideMessage();
            active = false;
        });
    }
}

import { Container, Sprite, Texture, FederatedPointerEvent } from "pixi.js";
import sound from "../../modules/sound";
import Display from "./display";
import Button from "./button";
import { FederatedEvent } from "pixijs";
import { gameSize } from "@/store/base-state";

const LAYOUT = [
    ["7", "8", "9"],
    ["4", "5", "6"],
    ["1", "2", "3"],
    ["C", "0", "OK"],
];

const WIDTH = 180;
const HEIGHT = 270;
const XGAP = 60;
const YGAP = 50;
const NUMPAD_MARGIN = 10;

type T_Callback = (receiverId: string, code: string) => void;

export default class Numpad extends Container {
    display: Display;
    tween = null;

    constructor(public player: number, x: number, y: number, public receiverId: string, public callback: (code: string, reciverID: string) => void) {
        super();
        this.name = `numpad${player}`;
        this.position.set(x, y);

        this.createBackground(player);
        this.createButtons();
        this.display = new Display();
        this.display.position.set(0, 50);
        this.addChild(this.display);
    }

    createBackground(player: number) {
        const bg = new Sprite(Texture.from("menu-panel1"));
        bg.anchor.set(0.5, 0);
        bg.interactive = true;
        bg.on("pointertap", () => {
            // this.parent.removeChild(this);
        });
        this.addChild(bg);
    }

    createButtons() {
        const startX = -XGAP;

        let x: number;
        let y = 100;
        for (let r = 0; r < LAYOUT.length; r++) {
            x = startX;
            for (let c = 0; c < LAYOUT[r].length; c++) {
                const btn = new Button(LAYOUT[r][c]);

                btn.position.set(x, y);
                x += XGAP;

                this.addChild(btn);
                btn.on("pointerdown", (event: FederatedPointerEvent) => {
                    this.onButtonDown(event);
                });
                btn.on("pointerup", (event: FederatedPointerEvent) => {
                    this.onButtonUp(event, btn.name || "");
                });
            }
            y += YGAP;
        }
    }

    onButtonDown = (event: FederatedPointerEvent) => {
        getSound().play("blup", 0.5);
        (<Button>event.currentTarget).setActive(true);
        this.callback("", "");
    };

    onButtonUp = (event: FederatedPointerEvent, label: string) => {
        (<Button>event.currentTarget).setActive(false);
        switch (label) {
            case "OK":
                this.callback(this.receiverId, this.display.getCode());
                break;
            case "C":
                this.display.back();
                break;
            default:
                this.display.addDigit(label);
                break;
        }
    };

    static getPos(player: number): { x: number; y: number } {
        const size = gameSize();
        switch (player) {
            case 0:
                return {
                    x: size.w * 0.5,
                    y: HEIGHT + NUMPAD_MARGIN,
                };
            case 1:
                return {
                    x: size.w - HEIGHT - NUMPAD_MARGIN,
                    y: size.h * 0.5,
                };
            case 2:
                return {
                    x: size.w * 0.5,
                    y: size.h - HEIGHT - NUMPAD_MARGIN,
                };
            case 3:
                return {
                    x: HEIGHT + NUMPAD_MARGIN,
                    y: size.h * 0.5,
                };
        }
        return { x: 0, y: 0 };
    }
}

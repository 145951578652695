import * as table from "../modules/table";

const TIMEOUT = 1000 * 60 * 5; // 30s

export default class WS {
    protocol = "ws://";
    autoReconnect = true;
    disabled = false;
    fileSize = 0;
    dataLen = 0;
    status = false;
    keepAliveFlag = false;

    ws: WebSocket | null = null;
    buffer: Uint8Array = new Uint8Array();
    lastSentMess: string = "";
    timerId = 0;
    lastMessageTime: number = 0;
    erroCounter = 0;

    host: string;
    callback: Function;

    constructor(host: string, callback: (mess: table.I_RecMessage) => void) {
        this.host = host;
        this.callback = callback;

        // if (window.location.protocol == "https:") {
        //     this.protocol = "wss://";
        // }
    }

    start() {
        // let path = "pgstation";
        // if (window.location.host.search("test") != -1 || window.location.host.search("localhost") != -1) {
        //     path += "_test";
        // }

        // let url = "127.0.0.1:5065";
        let url = `${this.protocol}${this.host}`;
        this.ws = new WebSocket(url);
        this.ws.binaryType = "arraybuffer";
        this.ws.onmessage = (e) => {
            if (e.data instanceof ArrayBuffer) {
                this.buffer.set(new Uint8Array(e.data), this.dataLen);
                this.dataLen += e.data.byteLength;
                if (e.data.byteLength < 8192) {
                    this.dataLen = 0;
                    this.sendEvent({
                        id: "fileLoaded",
                        data: this.buffer.buffer,
                    });
                } else {
                    this.send({
                        cmd: "nextFilePart",
                    });
                }
            } else {
                let messages = e.data.split("\n");
                for (let i = 0; i < messages.length; i++) {
                    if (messages[i].length) {
                        console.log(messages[i]);
                        this.sendEvent({
                            id: "message",
                            data: JSON.parse(messages[i]),
                        });
                    }
                }
            }
        };

        this.ws.onclose = () => {
            this.status = false;
            // this.erroCounter++;
            // if (this.erroCounter == 3) {
            //     this.disabled = true;
            // }
            if (this.disabled === false) {
                this.sendEvent({
                    id: "disconnected",
                });
                if (this.autoReconnect) {
                    window.setTimeout(() => {
                        this.start();
                    }, 5000);
                }
            }
        };

        this.ws.onopen = () => {
            this.erroCounter = 0;
            this.status = true;
            this.lastMessageTime = Date.now();
            this.keepAlive();
            this.sendEvent({
                id: "connected",
            });
        };

        this.ws.onerror = (error) => {
            console.log("Socket error:" + error);
        };
    }

    send(data) {
        if (this.status && this.ws) {
            let json = JSON.stringify(data);
            this.ws.send(json);
            this.lastSentMess = json;
            this.lastMessageTime = Date.now();
        }
        return this.status;
    }

    sendResponse(cmd: string, ack: string, msg: {}) {
        let mess = {
            ack: ack,
            cmd: cmd,
            msg: msg,
        };
        if (this.status && this.ws) {
            let json = JSON.stringify(mess);
            console.log(json);
            this.ws.send(json);
            this.lastSentMess = json;
        }
        return this.status;
    }

    sendLastMess() {
        if (this.ws) {
            this.ws.send(this.lastSentMess);
        }
    }

    sendEvent(args) {
        if (this.callback) {
            this.callback(args);
        }
    }

    newFileDownloading(size) {
        this.fileSize = size;
        this.dataLen = 0;
        this.buffer = new Uint8Array(size);
        this.send({
            cmd: "nextFilePart",
        });
    }

    setDisabled(state) {
        this.disabled = state;
    }

    reconnect() {
        window.setTimeout(() => {
            this.start();
        }, 1000);
    }

    setAutoConnect(mode) {
        this.autoReconnect = mode;
    }

    close = () => {
        this.disabled = true;
        this.autoReconnect = false;
        if (this.ws) {
            this.ws.close();
        }
    };

    log(data) {
        this.send({
            cmd: "log",
            data: data,
        });
    }

    keepAlive = () => {
        if (this.ws && this.keepAliveFlag && this.ws.readyState == this.ws.OPEN) {
            if (Date.now() - this.lastMessageTime >= TIMEOUT) {
                this.send({
                    cmd: "ping",
                });
            }
        }
        window.clearTimeout(this.timerId);
        this.timerId = window.setTimeout(() => {
            this.keepAlive();
        }, TIMEOUT);
    };

    cancelKeepAlive() {
        if (this.timerId) {
            window.clearTimeout(this.timerId);
        }
    }
}

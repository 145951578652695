import * as PIXI from "pixi.js";
import { PixiPlugin } from "gsap/PixiPlugin.js";
import { gsap } from "gsap";
import RandomDataGenerator from "./modules/rnd";

import { resize } from "./common/resize";
import { getState } from "./store/base-state";
import useFetch from "./common/fetch";
import { IGameInfo, setGameInfo } from "./store/gameinfo";
import { connectTable } from "./modules/table-connection";
import { initPixi } from "./game/init-pixi";

window["PIXI"] = PIXI;

PIXI.settings.PREFER_ENV = PIXI.ENV.WEBGL2;

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

// document.body.addEventListener("pointerup", fullScreen);

declare global {
    interface Window {
        // vueApp: Vue;
        pixiApp: PIXI.Application | null;
        RNG: RandomDataGenerator;

        // viewport: Viewport;
    }
    interface CanvasRenderingContext2D {
        roundedRectangle(x: number, y: number, width: number, height: number, rounded: number);
    }
}

// .$mount("#app");

window.onresize = resize;

window.RNG = new RandomDataGenerator();

// if ("serviceWorker" in navigator) {
//     window.addEventListener("load", () => {
//         navigator.serviceWorker.register("./sw.js", { scope: "./" });
//     });
// }

window.addEventListener("contextmenu", function (e) {
    e.preventDefault();
    return false;
});

(async function () {
    // const langs = (await useFetch("langs.json", { method: "GET" })) as I_LangData[];
    const gameInfo = (await useFetch("gameinfo.json", { method: "GET" })) as IGameInfo[];
    Promise.all([gameInfo]).then((values) => {
        // if (langs) {
        //     setLang(langs);
        // }
        if (gameInfo) {
            setGameInfo(gameInfo);
        }
    });
})();

declare let FontFace;

(async function () {
    let roboto = new FontFace("Roboto Slab", "url(./css/RobotoSlab-Regular.woff2)");
    let digit = new FontFace("Digit", "url(./css/digit7.woff2)");
    let fonts = await Promise.all([roboto.load(), digit.load()]);
    fonts.forEach((font) => document.fonts["add"](font));
    document.body.classList.add("fonts-loaded");
})();

console.log("Version: ", getState("version"));
(async () => {
    initPixi();
    connectTable();
})();

import * as gamestate from "@/store/game-state";
import { forceReload } from "../common/http_request";
import { getApi, getState, I_LobbyDataResponse, setState } from "../store/base-state";
import useFetch from "@/tools/fetch";
import * as gamemodel from "@/store/model/game";
import { initPixi, loadAssets } from "@/game/init-pixi";

let loginAttempt = 3;

interface ILogin {
    tid: string;
    free?: boolean;
    gameTime?: number;
    timeBudget?: number;
    gameEndpoint?: number;
}

export const lobbyLogin = async () => {
    if (!window.navigator.onLine) {
        waitForOnline();
        return;
    }

    //login
    const loginResp = (await useFetch(`${getApi()}/login/${getState("hid")}`, { method: "GET" })) as ILogin;
    if (loginResp) {
        setState("tid", loginResp.tid);
        setState("free", loginResp.free);
        if (loginResp.gameEndpoint != undefined) {
            setState("gameEndpoint", loginResp.gameEndpoint);
        }

        if (loginResp.gameTime != undefined) {
            setState("gameTime", loginResp.gameTime);
        }
        if (loginResp.timeBudget != undefined) {
            setState("timeBudget", loginResp.timeBudget);
        }
        await getLobbyData();

        await openLobby();
    } else {
        loginAttempt--;
        if (loginAttempt == 0) {
            return;
        }
        window.setTimeout(lobbyLogin, 5000);
    }
};

async function getLobbyData() {
    const data = (await useFetch(`${getApi()}/lobbydata/${getState("tid")}`, { method: "GET" })) as I_LobbyDataResponse;
    if (data) {
        if (data.version != undefined) {
            if (data.version > getState("version")) {
                forceReload("lobby");
            }
        }
        setState("tableColor", data.tableColor);
        setState("gameCouponEnabled", data.gameCouponEnabled);
        setState("adminPinCode", data.adminPinCode);
        setState("enabledLangs", data.languages);
        setState("lobbyBackgroundUrl", data.lobbyBackgroundImage);
        setState("tariff", data.tariff);
        setState("currency", data.currency);
        setState("whereToBuy", data.whereToBuy);
        setState("private", data.private);
        setState("creditSystem", data.creditSystem);
        gamestate.setState("gameData", data.games);
    }
}

async function getGameData() {
    const data = (await useFetch(`${getApi()}/gamedata/${getState("tid")}`, { method: "GET" })) as gamemodel.I_GameData[];
    gamestate.setState("gameData", data);
}

async function openLobby() {
    await loadAssets();
    let htmlLoader = document.getElementsByClassName("progress-loader")[0];
    if (htmlLoader) {
        htmlLoader.parentNode?.removeChild(htmlLoader);
    }
    // if (router.currentRoute.value.path != "gamelobby") {
    // router.replace("/gamelobby");
    // }
}

function waitForOnline() {
    window.setTimeout(async () => {
        loginAttempt--;
        if (loginAttempt > 0) {
            await lobbyLogin();
            return;
        }
    }, 5000);
}
